import { Injectable } from '@angular/core';
import { GenericListDataService } from './generic-list-data.service';
import { ApiDatasourcePath, ListNames } from '../utils/constants';

/**
 * restituisce lista COMPLETA delle sessioni
 */
@Injectable({
  providedIn: 'root',
})
export class TagListDataService extends GenericListDataService<any> {
  typeName = ListNames.TAG_LIST_NAME;
  protected apiDatasourcePath = ApiDatasourcePath.TAG;
}
