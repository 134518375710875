<div fxLayout="column" fxLayoutAlign="start stretch" fxFill *ngIf="element">
  <!--INIZIO TAG-->
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="0px"
    style="width: 100%"
  >
    <ng-container *ngFor="let tag of element.SessioneDetailTag">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="tag.Colore"
        class="clickable"
        [ngStyle]="{ 'background-color': tag.Colore }"
        style="width: 100%; min-height: 15px"
        (click)="onTagBtnClicked(tag.TagId)"
      >
        <span [ngStyle]="{ color: coloreOpposto(tag.Colore) }">
          {{ tag.Descrizione }}
        </span>
      </div>
    </ng-container>
  </div>
  <!--FINE TAG-->

  <!--INFO ORARIO-->
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="12px"
    style="padding: 8px; margin-top: 12px"
    [ngStyle]="{
      'background-color': eventoDataService.colorePrincipale,
      color: eventoDataService.coloreSecondario
    }"
  >
    <div fxLayout="row">
      <div fxFlex="30" style="margin-top: auto; margin-bottom: auto">
        {{ element.DataSessione | withTimeZone | date: "dd/MM/yyyy" }}
      </div>
      <div fxFlex="70" >
        <h3
        (click)="onSalaBtnClicked()"
        class="clickable"
        *ngIf="sala | async"
        style="margin-top: auto; margin-bottom: auto"
        >
          {{ (sala | async)?.Descrizione }}
        </h3>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row">
      <div fxFlex="20" style="margin-top: auto; margin-bottom: auto">
        {{ element.OraInizio | withTimeZone | date: "HH:mm" }} -
        {{ element.OraFine | withTimeZone | date: "HH:mm" }}
      </div>
      <div fxFlex="80" style="margin-top: auto; margin-bottom: auto">
        <h2>{{ element.Titolo }}</h2>
      </div>
    </div>
  </div>
  <!--FINE INFO ORARIO-->
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    style="margin-top: 20px"
    *ngIf="googleCalendarEventUrl"
  >
    <a
      mat-stroked-button
      i18n
      [href]="googleCalendarEventUrl"
      target="_blank"
      [ngStyle]="{
        'background-color': coloreSecondario,
        color: colorePrincipale
      }"
    >
      AGGIUNGI AL CALENDARIO
    </a>
  </div>
  <!--LISTA MODERATORI E RELATORI-->
  <mat-list>
    <ng-container *ngFor="let tipoPersona of element.SessioniTipiPersone">
      <div mat-subheader style="height: 30px !important; padding: 0 16px">
        {{ tipoPersona.Descrizione }}
      </div>
      <mat-list-item
        *ngFor="let personaId of getPersoneIds(tipoPersona.Id)"
        style="height: 35px !important"
      >
        <div
          mat-line
          (click)="onPersonaBtnClicked(personaId)"
          class="clickable"
        >
          {{ getDescrizionePersonaById(personaId) }}
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-list>

  <!--FINE LISTA MODERATORI E RELATORi-->
  <!--LISTA RELAZIONI-->
  <div  fxFlex>
    <h2 i18n>Relazioni</h2>
    <app-relazioni [sessioneID]="element.Id" [showDate]="false" [nascondiNomeRelatore]="false"></app-relazioni>
  </div>

  <!--FINE LISTA RELAZIONI-->
</div>
