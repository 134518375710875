import { NavigationComponent } from './navigation/navigation.component';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ProgrammaNavigabileCoreModule } from './core/programma-navigabile-core.module';
import { SharedModule } from './shared/shared.module';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { SessioniComponent } from './sessioni/sessioni.component';
import { SaleComponent } from './sale/sale.component';
import { TagComponent } from './tag/tag.component';
import { PersoneComponent } from './persone/persone.component';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { SalaDetailComponent } from './sale/sala-detail/sala-detail.component';
import { RelazioniComponent } from './relazioni/relazioni.component';
import { RelazioneDetailComponent } from './relazioni/relazione-detail/relazione-detail.component';
import { SessioneDetailComponent } from './sessioni/sessione-detail/sessione-detail.component';
import { PersonaDetailComponent } from './persone/persona-detail/persona-detail.component';
import { CronologicoComponent } from './cronologico/cronologico.component';
import { RelazioneRowComponent } from './relazioni/relazione-row/relazione-row.component';
import { TipiPersoneComponent } from './persone/tipi-persone/tipi-persone.component';
import { PersonaRowComponent } from './persone/persona-row/persona-row.component';
import { TagDetailComponent } from './tag/tag-detail/tag-detail.component';

import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';

import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SessioniRowComponent } from './sessioni/sessioni-row/sessioni-row.component';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    NotFoundPageComponent,
    SessioniComponent,
    SaleComponent,
    TagComponent,
    PersoneComponent,
    SalaDetailComponent,
    RelazioniComponent,
    RelazioneDetailComponent,
    SessioneDetailComponent,
    PersonaDetailComponent,
    CronologicoComponent,
    RelazioneRowComponent,
    TipiPersoneComponent,
    PersonaRowComponent,
    TagDetailComponent,
    SessioniRowComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    ProgrammaNavigabileCoreModule, // modulo con i providers
    AppRoutingModule, // modulo con le routes
    HttpClientModule,
    SharedModule,
    MomentTimezonePickerModule, // https://www.npmjs.com/package/moment-timezone-picker
    NgSelectModule,
    NgAddToCalendarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'it' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
