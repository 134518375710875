import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProgrammaNavigabileMaterialModule } from '../core/programma-navigabile-material.module';
import { WithTimeZonePipe } from './pipes/with-time-zone.pipe';

@NgModule({
    imports: [
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CommonModule,
        ProgrammaNavigabileMaterialModule,
    ],
    exports: [
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CommonModule,
        ProgrammaNavigabileMaterialModule,
        WithTimeZonePipe,
    ],
    declarations: [WithTimeZonePipe]
})
export class SharedModule {}
