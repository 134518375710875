import { Sessione } from 'src/app/shared/models/sessione.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MasterDataService } from '../shared/services/master-data.service';
import { SessioniListDataService } from '../shared/services/sessioni-list-data.service';

/**
 * Cliccando sul pulsante Cronologico, verrà mostrato, in alto, un selettore dei giorni del
 * PN e, sotto, la lista delle sessioni previste per il giorno selezionato
 * (di default il giorno corrente o il primo, se il giorno corrente non fa
 * parte di quelli del PN), ordinate cronologicamente e per sala.
 *
 * Per ogni sessione verrà mostrato:
 *  + titolo
 *  + data e ora
 *  + sala
 *  + eventuale banda suddivisa fra i colori dei tag associati.
 *
 * Cliccando su un elemento della lista, verrà visualizzata la componente Sessione.
 */
@Component({
  selector: 'app-cronologico',
  templateUrl: './cronologico.component.html',
  styleUrls: ['./cronologico.component.scss'],
})
export class CronologicoComponent implements OnInit {
  // tabs = ['First', 'Second', 'Third'];
  selected = new FormControl(0);

  private currentDate: Date = new Date(2021, 8, 15);

  constructor(
    @Inject(SessioniListDataService)
    private dataService: SessioniListDataService,
    public refreshService: MasterDataService
  ) {}

  sessioniList: Sessione[] = [];

  private firstLoad: boolean = true;

  ngOnInit(): void {
    this.dataService.dataList.subscribe((res) => {
      if (res) {
        this.sessioniList = res;
        this.splitSessionByDay();
        if (this.firstLoad) {
          this.selectTodayTab();
        }
        this.firstLoad = false;
      }
    });
  }

  private selectTodayTab(): void {
    let founded = false;
    for (let i = 0; i < this.days.length; i++) {
      const d = this.days[i];
      if (this.isCurrentDay(new Date(d))) {
        this.selected.setValue(i);
        founded = true;
        break;
      }
    }
    if (!founded) {
      this.selected.setValue(0);
    }
  }

  /**
   * Restituisce true se la data in input è oggi
   * @param d Date
   * @returns boolean
   */
  isCurrentDay(d: Date): boolean {
    return (
      d.getDate() === this.currentDate.getDate() &&
      d.getMonth() === this.currentDate.getMonth() &&
      d.getFullYear() === this.currentDate.getFullYear()
    );
  }

  sessionsByDays: Record<string, Sessione[]> = {};

  days: string[] = [];

  private splitSessionByDay(): void {
    this.sessionsByDays = {};
    if(this.sessioniList) {
      this.sessioniList.forEach((sessione) => {
        const d = this.getDateString(new Date(sessione.DataSessione));
        this.sessionsByDays[d]
          ? this.sessionsByDays[d].push(sessione)
          : (this.sessionsByDays[d] = [sessione]);
      });
      this.days = Object.keys(this.sessionsByDays);
    }
  }

  getDateString(d: Date): string {
    return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
  }
}
