import { EventoDataService } from './../../shared/services/evento-data.service';
import { Component, Inject } from '@angular/core';
import { Sessione } from 'src/app/shared/models/sessione.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SessioniListDataService } from 'src/app/shared/services/sessioni-list-data.service';
import { SaleListDataService } from 'src/app/shared/services/sale-list-data.service';
import { PersoneListDataService } from 'src/app/shared/services/persone-list-data.service';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { RelazioneESessioneDetailComponent } from 'src/app/shared/components/relazione-e-sessione-detail.component';

import {
  NgAddToCalendarService,
  ICalendarEvent,
} from '@trademe/ng-add-to-calendar';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

/**
 * Questo componente visualizza i dettagli di una sessione, in particolare:
   - titolo
   - lista dei tag associati. Per ogni tag verrà mostrato:
        + nome
        + colore

  Cliccando su un elemento della lista, verrà visualizzato il componente Tag.
   - data
   - ora inizio
   - ora fine
   - nome della sala. Cliccando sul nome della sala verrà visualizzato il componente Sala
   - lista dei moderatori. per ogni moderatore verrà mostrato:
        + nome
        + cognome

      Cliccando su un elemento della lista, verrà visualizzato il componente Persona.
    - lista delle relazioni. Per ogni relazione verrà mostrato:
          + titolo
          + data
          + ora di inizio
          + ora di fine
          + eventuale banda suddivisa fra i colori dei tag associati. 
    Cliccando su un elemento della lista, verrà visualizzato il componente Relazione.
    - pulsante per aggiungere al proprio calendario la sessione.
 */
@Component({
  selector: 'app-sessione-detail',
  templateUrl: './sessione-detail.component.html',
  styleUrls: ['./sessione-detail.component.scss'],
})
export class SessioneDetailComponent extends RelazioneESessioneDetailComponent<Sessione> {
  constructor(
    @Inject(SessioniListDataService) dataService: SessioniListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService,
    @Inject(SaleListDataService) saleDataService: SaleListDataService,
    @Inject(EventoDataService) eventoDataService: EventoDataService,
    @Inject(PersoneListDataService)
    personeDataService: PersoneListDataService,
    private _addToCalendarService: NgAddToCalendarService,
    private _sanitizer: DomSanitizer
  ) {
    super(
      dataService,
      router,
      route,
      refreshService,
      saleDataService,
      eventoDataService,
      personeDataService
    );
    this.personeDataService.dataList.subscribe((res) => {
      if (res) {
        this.persone = res;
      }
    });
  }

  onLoadedData(): void {
    super.onLoadedData();
    this.loadCalendarLinkInfo();
    this.loadSalaInfo();
    console.log('sessione', this.element);
    console.log('TipiPersone', this.element?.SessioniTipiPersone);
  }

  get salaID(): number | undefined {
    return this.element?.SalaId;
  }

  getPersoneIds(tipoPersonaId: number): number[] {
    if (this.element && this.element?.SessioniChairman) {
      const filtered = this.element.SessioniChairman.filter(
        (items) => items.TipoPersonaId === tipoPersonaId
      );
      if (filtered) {
        return filtered.map((el) => el.PersonaId);
      }
    }
    return [];
  }

  public googleCalendarEventUrl?: SafeUrl;

  /**
   * https://www.npmjs.com/package/@trademe/ng-add-to-calendar
   */
  loadCalendarLinkInfo(): void {
    // TODO caricare link diverso a seconda del dispositivo
    let newEvent: ICalendarEvent = {
      // Event title
      title: this.element!.Titolo,
      // Event start date
      start: new Date(this.element!.OraInizio),
      // Event duration (IN MINUTES)
      duration: this.calcolaDifferenzaMinuti(
        this.element!.OraFine,
        this.element!.OraInizio
      ),
      // If an end time is set, this will take precedence over duration (optional)
      end: new Date(this.element!.OraFine),
      // Event Address (optional)
      address: '',
      // Event Description (optional)
      description: this.element!.Descrizione,
    };
    this.googleCalendarEventUrl = this._sanitizer.bypassSecurityTrustUrl(
      this._addToCalendarService.getHrefFor(
        this._addToCalendarService.calendarType.iCalendar,
        newEvent
      )
    );
  }

  /**
   * Restituisce la differenza tra due orari in minuti
   *
   * @param ora1 Date
   * @param ora2 Date
   * @returns number
   */
  calcolaDifferenzaMinuti(ora1: Date, ora2: Date): number {
    const diff = new Date(ora1).getTime() - new Date(ora2).getTime();
    if (isNaN(diff)) {
      return 0;
    }
    return diff / (1000 * 60);
  }
}
