import { map } from 'rxjs/operators';
import { Sala } from 'src/app/shared/models/sala.model';
import { Sessione } from './../shared/models/sessione.model';
import { Persona } from './../shared/models/persona.model';
import { Component, Inject, Input } from '@angular/core';
import { PnGenericListComponent } from '../shared/components/pn-generic/pn-generic-list.component';
import { SessioniListDataService } from './../shared/services/sessioni-list-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSelectionListChange } from '@angular/material/list';
import { RoutePath } from '../shared/utils/constants';
import { MasterDataService } from '../shared/services/master-data.service';
import { SaleListDataService } from '../shared/services/sale-list-data.service';
import { PersoneListDataService } from '../shared/services/persone-list-data.service';

@Component({
  selector: 'app-sessioni',
  templateUrl: './sessioni.component.html',
  styleUrls: ['./sessioni.component.scss'],
})
export class SessioniComponent extends PnGenericListComponent<Sessione> {
  /**
   * elenco delle sale
   */
  private listaSale?: Sala[] = [];
  private persone: Persona[] = [];
  constructor(
    @Inject(SessioniListDataService) dataService: SessioniListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService,
    @Inject(PersoneListDataService) private personeDataService: PersoneListDataService,
    @Inject(SaleListDataService) private saleDataService: SaleListDataService
  ) {
    super(dataService, router, route, refreshService);
    // si carica l'elenco delle sale
    this.saleDataService.dataList.subscribe((res) => {
      this.listaSale = res;
    });
    //Carico elenco persone
    this.getPersoneList();
  }
  _tagID?: number;
  @Input() showDate: boolean = true;

  /**
   * parametro per filtrare le sessioni in base alla salaID
   */
  @Input() set tagID(val: number | undefined) {
    this._tagID = val;
    if (val) {
      this.list = this.dataService.dataList.pipe(
        map((items) =>
          items.filter((item) =>
            item.SessioneDetailTag
              ? item.SessioneDetailTag.find((t) => t.TagId === val)
              : false
          )
        )
      );
    }
  }

  get tahID(): number | undefined {
    return this._tagID;
  }

  _salaID?: number;

  /**
   * parametro per filtrare le sessioni in base alla salaID
   */
  @Input() set salaID(val: number | undefined) {
    this._salaID = val;
    if (val) {
      this.list = this.dataService.dataList.pipe(
        map((items) => items.filter((item) => item.SalaId === val))
      );
    }
  }

  get salaID(): number | undefined {
    return this._salaID;
  }

  onSelectionChanged(event: MatSelectionListChange): void {
    this.goToPage([RoutePath.SESSIONI_ROUTE, event.options[0].value]);
  }

  /**
   * ritorna true se si deve mostrare l'header con il titolo
   */
  @Input() showHeader: boolean = true;

  /**
   * Restituisce il nome della sala tramite il suo id
   *
   * @param salaID number
   * @returns string
   */
  getNomeSalaById(salaID: number): string {
    if (this.listaSale) {
      const s = this.listaSale.find((el) => el.Id === salaID);
      if (s) {
        return s.Descrizione;
      }
    }
    return '';
  }

  trovaRelatore(item: Sessione){
    let id: number;
    let name: string;
    if(item.SessioniChairman){
      id = item.SessioniChairman[0].PersonaId
      name = this.getNomePersonaById(id)
      return name
    }
    return ''
  }

  getPersoneList(){
    this.personeDataService.dataList.subscribe((list) => this.persone = list)
  }

  getNomePersonaById(id: number) {
    let pers: any;
    pers = this.persone.find(e => e.Id == id)
    if(pers != undefined){
      return PersoneListDataService.getDescrizionePersona(pers)
    }
    return ''
  }


}
