
<div >
  <div *ngIf="viewport == true">
    <ng-container *ngTemplateOutlet="viewPort"></ng-container>
  </div>
  <div *ngIf="!viewport">
    <ng-container *ngTemplateOutlet="NoViewPort"></ng-container>
  </div>

</div>

<ng-template #viewPort>
  <cdk-virtual-scroll-viewport [itemSize]="50" class="main-virtual-viewport" fxFlex>
    <mat-selection-list
      class="relazioni-list"
      #selectionList
      [multiple]="false"
      (selectionChange)="onSelectionChanged($event)"
    >
      <mat-list-option
        *cdkVirtualFor="let relazione of list | async; let i = index"
        [value]="relazione"
        class="list-option"
  
      >
        <app-relazione-row
          [showSalaDetail]="showSala"
          [showDateDetail]="showDate"
          [nascondiNomeRelatore]="nascondiNomeRelatore"
          [relazione]="relazione"
          [ngClass]="{
            'cursorPointer' : relazione.UrlAttionline != '' ,
            'cursorDefault': relazione.UrlAttionline == '' || relazione.UrlAttionline == null }"
        ></app-relazione-row>
      </mat-list-option>
    </mat-selection-list>
  </cdk-virtual-scroll-viewport>
</ng-template>




<ng-template #NoViewPort>
  <div fxFlex>
    <mat-selection-list
      class="relazioni-list"
      #selectionList
      [multiple]="false"
      (selectionChange)="onSelectionChanged($event)"
    >
      <mat-list-option
        *ngFor="let relazione of list | async; let i = index"
        [value]="relazione"
        class="list-option"
  
      >
        <app-relazione-row
          [showSalaDetail]="showSala"
          [showDateDetail]="showDate"
          [nascondiNomeRelatore]="nascondiNomeRelatore"
          [relazione]="relazione"
          [ngClass]="{
            'cursorPointer' : relazione.UrlAttionline != '' ,
            'cursorDefault': relazione.UrlAttionline == '' || relazione.UrlAttionline == null }"
        ></app-relazione-row>
      </mat-list-option>
    </mat-selection-list>
  </div>
</ng-template>



