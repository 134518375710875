import { Component, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PnGenericDetailComponent } from 'src/app/shared/components/pn-generic/pn-generic-detail.component';
import { Persona } from 'src/app/shared/models/persona.model';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { PersoneListDataService } from 'src/app/shared/services/persone-list-data.service';

/**
 * Questo componente visualizza i dettagli di una persona (moderatore e/o relatore), in particolare:
    - nome e cognome
    - immagine
    - lista delle sessioni moderate. Per ogni sessione verrà mostrato:
        + titolo
        + data
        + ora di inizio
        + ora di fine
        + sala
        + eventuale banda suddivisa fra i colori dei tag associati.

     Cliccando su un elemento della lista, verrà visualizzato il componente Sessione.
    - lista delle relazioni presentate. Per ogni relazione verrà mostrato:
        + titolo
        + data
        + ora di inizio
        + ora di fine
        + eventuale banda suddivisa fra i colori dei tag associati.

        Cliccando su un elemento della lista, verrà visualizzato il componente Relazione.
 */
@Component({
  selector: 'app-persona-detail',
  templateUrl: './persona-detail.component.html',
  styleUrls: ['./persona-detail.component.scss'],
})
export class PersonaDetailComponent extends PnGenericDetailComponent<Persona> {
  constructor(
    @Inject(PersoneListDataService) dataService: PersoneListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService
  ) {
    super(dataService, router, route, refreshService);
  }
}
