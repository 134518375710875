import { Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GenericListDataService } from '../../services/generic-list-data.service';
import { MasterDataService } from '../../services/master-data.service';

@Directive()
export abstract class PnGenericComponent<T> {
  constructor(
    protected dataService: GenericListDataService<T>,
    protected router: Router,
    protected route: ActivatedRoute,
    protected refreshService: MasterDataService
  ) {}

  goToPage(commands: any[]): void {
    this.router.navigate([this.refreshService.cms2EventId, ...commands]);
  }

  get colorePrincipale(): string | undefined {
    return this.refreshService.colorePrincipale;
  }

  get coloreSecondario(): string | undefined {
    return this.refreshService.coloreSecondario;
  }
}
