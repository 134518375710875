import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './navigation/navigation.component';
import { PROGRAMMA_NAVIGABILE_ROUTES } from './routes/programma-navigabile.routes';

const routes: Routes = [
  {
    path: ':Id',
    component: NavigationComponent,
    data: {
      title: 'Home',
      animation: 'NavigationPage',
    },
    children: PROGRAMMA_NAVIGABILE_ROUTES,
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    data: {
      title: '404',
      animation: '404Page',
    },
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
