import {
  trigger,
  animate,
  style,
  transition,
  query,
  group,
  stagger,
} from '@angular/animations';

export const routeAnimations = trigger('routeAnimations', [
  transition('* <=> *', [
    group([
      query(
        ':enter .block',
        stagger(0, [
          style({ transform: 'translateY(100px)', opacity: 0 }),
          animate(
            '1s linear',
            style({ transform: 'translateY(0px)', opacity: 1 })
          ),
        ]),
        { optional: true }
      ),
      query(
        ':enter .block-h',
        stagger(200, [
          style({ transform: 'translateX(100px)', opacity: 0 }),
          animate(
            '1s ease-in-out',
            style({ transform: 'translateX(0px)', opacity: 1 })
          ),
        ]),
        { optional: true }
      ),
    ]),
  ]),
]);
