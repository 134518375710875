import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Evento, EVENT_PROGRAM_PROPERTIES } from '../models/evento.model';
import { ListNames, ApiDatasourcePath } from '../utils/constants';
import { GenericListDataService } from './generic-list-data.service';
import { LocalStorageRefService } from './local-storage.service';

/**
 * restituisce evento?: Evento con le info dell'evento
 */
@Injectable({
  providedIn: 'root',
})
export class EventoDataService
  extends GenericListDataService<Evento>
  implements OnDestroy
{
  typeName = ListNames.EVENTO_LIST_NAME;

  protected apiDatasourcePath = ApiDatasourcePath.EVENTO;

  constructor(
    httpClient: HttpClient,
    route: ActivatedRoute,
    _localStorageRefService: LocalStorageRefService
  ) {
    super(httpClient, route, _localStorageRefService);
    this.dataList.subscribe((res: Evento[] | Evento | undefined) => {
      if (res) {
        if (Array.isArray(res)) {
          // se la response ci restituisce un array, si prende il primo elemento
          if (res.length === 1 && res[0].Id) {
            this.onEventLoadedFromServer(res[0]);
            return;
          }
        } else {
          // se non è array
          if (res.Id) {
            this.onEventLoadedFromServer(res);
            return;
          }
        }
      }
      this.onEventLoadedFromServer(undefined);
    });
  }

  /**
   * Restituisce true se una delle proprietà che vengono usate nella app angular
   * sono cambiate
   *
   * @param ev1 Evento
   * @param ev2 Evento
   * @returns boolean
   */
  private areEventProgramPropertiesChanged(ev1: Evento, ev2: Evento): boolean {
    for (let i = 0; i < EVENT_PROGRAM_PROPERTIES.length; i++) {
      const propName = EVENT_PROGRAM_PROPERTIES[i];
      if (ev1[propName as keyof Evento] !== ev2[propName as keyof Evento]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Chiamato quando si sono ottenuti i dati dal server
   *
   * @param ev Evento | undefined
   */
  private onEventLoadedFromServer(ev: Evento | undefined): void {
    const oldEvento = this._evento;
    this._eventoSubject$.next(ev); // si chiama sempre _eventoSubject
    // si deve valutare se chiamare _eventProgramChangeSubject
    if (!ev || !oldEvento) {
      // se ev è undefined
      this._eventProgramChangeSubject$.next(ev);
    } else {
      // se si entra qui significa che già avevamo precedentemente recupeato le info
      // dell'evento. Dobbiamo confrontare i nuovi valori di ev con i vecchi di _evento.
      // qui ev è definito
      // si controllano le differenze dei campi su cui si basa il programma
      // navigabile
      if (this.areEventProgramPropertiesChanged(oldEvento, ev)) {
        // se ci sono delle differenze si chiama _eventProgramChangeSubject
        this._eventProgramChangeSubject$.next(ev);
      }
    }
  }

  private get _evento(): Evento | undefined {
    return this._eventoSubject$.getValue();
  }

  private _eventoSubject$: BehaviorSubject<Evento | undefined> =
    new BehaviorSubject<Evento | undefined>(undefined);

  eventoObs: Observable<Evento | undefined> =
    this._eventoSubject$.asObservable();

  private _eventProgramChangeSubject$: BehaviorSubject<Evento | undefined> =
    new BehaviorSubject<Evento | undefined>(undefined);

  eventProgramObs: Observable<Evento | undefined> =
    this._eventProgramChangeSubject$.asObservable();

  private timerSubscription?: Subscription;

  /**
   * avvia il timer per prendere eventuali nuove info sull'evento
   * dal server
   */
  startServerDataTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerSubscription = timer(0, environment.dataRefreshTimer).subscribe(
      (_res) => {
        this.loadServerData();
      }
    );
  }

  /**
   * restituisce il colore principale del programma se c'è,
   * altrimenti rende il colore principale dell'evento stesso
   */
  get colorePrincipale(): string | undefined {
    if (this._evento) {
      return (
        this._evento.ProgrammaNavigabileColorePrincipale ??
        this._evento.ColorePrincipale
      );
    }
    return;
  }

  /**
   * restituisce il colore secondario del programma se c'è,
   * altrimenti rende il colore secondario dell'evento stesso
   */
  get coloreSecondario(): string | undefined {
    if (this._evento) {
      return (
        this._evento.ProgrammaNavigabileColoreSecondario ??
        this._evento.ColoreSecondario
      );
    }
    return;
  }

  /**
   * restituisce il nome dell'evento
   */
  get nomeEvento(): string | undefined {
    return this._evento?.Nome;
  }

  /**
   * restituisce id del programma navigabile associato
   */
  get programmaEventoId(): number | undefined {
    return this._evento?.ProgrammaNavigabileEventoId;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.timerSubscription?.unsubscribe();
  }
}
