import { PersoneListDataService } from './persone-list-data.service';
import { Persona } from '../models/persona.model';
import { Sessione } from './../models/sessione.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  map,
} from 'rxjs/operators';
import { Relazione } from '../models/relazione.model';
import { RelazioniListDataService } from './relazioni-list-data.service';
import { SessioniListDataService } from './sessioni-list-data.service';

export interface SearchTerm {
  term: string;
}

/**
 * Digitando un minimo di tre caratteri nella casella di ricerca sempre disponibile sulla barra di navigazione, verrà avviata una ricerca in:
  - titolo delle sessioni
  - titolo delle relazioni
  - nome e cognome delle persone

  e compariranno, su un layer sovrastante i dati attualmente visualizzati, i risultati della ricerca suddivisi in tre sottosezioni, Sessioni, Relazioni e Persone, contenenti i risultati dei relativi tipi.

  - Per ogni sessione verrà mostrato:
      + titolo
      + data e ora
      + sala
      + eventuale banda suddivisa fra i colori dei tag associati.

  - Per ogni relazione verrà mostrato:
      + titolo
      + eventuale banda suddivisa fra i colori dei tag associati.

  - Per ogni persona verrà mostrato:
      + nome e cognome
      + foto se presente
      + numero di sessioni che modera
      + numero di relazioni che presenta.

Cliccando su un item delle tre liste, verrà visualizzata rispettivamente la componente Sessione, Relazione o Persona.
 */
@Injectable({ providedIn: 'root' })
export class SearchService {
  personeSearching = false;
  personeSearchingFailed = false;
  sessioniSearching = false;
  sessioniSearchingFailed = false;
  relazioniSearching = false;
  relazioniSearchingFailed = false;
  get searching() {
    return this.personeSearching || this.sessioniSearching;
  }
  get searchFailed() {
    return (
      this.relazioniSearchingFailed ||
      this.personeSearchingFailed ||
      this.sessioniSearchingFailed
    );
  }
  searchTerm = new BehaviorSubject<SearchTerm>({ term: '' });
  personeSearchResult: Observable<Persona[]>;
  sessioniSearchResult: Observable<Sessione[]>;
  relazioniSearchResult: Observable<Relazione[]>;

  constructor(
    private personeListDataService: PersoneListDataService,
    private sessioniListDataService: SessioniListDataService,
    private relazioniListDataService: RelazioniListDataService
  ) {
    this.personeSearchResult = this.searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.personeSearching = true)),
      switchMap((term) => {
        if (
          term == null ||
          term.term == null ||
          term.term.replace(' ', '') === '' ||
          term.term.length < 3
        ) {
          let self = this;
          setTimeout(() => {
            self.personeSearching = false;
          }, 2000);
          return of([] as Persona[]).toPromise();
        }
        const valoreFiltro = term.term.toLowerCase();

        // le persono vengono filtrate per nome e cognome
        return this.personeListDataService.dataList.pipe(
          map((items) =>
            items.filter(
              (item) =>
                (item.Cognome &&
                  item.Cognome.toLowerCase().includes(valoreFiltro)) ||
                (item.Nome && item.Nome.toLowerCase().includes(valoreFiltro))
            )
          )
        );
      }),
      tap(() => (this.personeSearching = false))
    );

    this.sessioniSearchResult = this.searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.sessioniSearching = true)),
      switchMap((term) => {
        if (
          term == null ||
          term.term == null ||
          term.term.replace(' ', '') === '' ||
          term.term.length < 3
        ) {
          setTimeout(() => {
            this.sessioniSearching = false;
          }, 2000);
          return of([] as Sessione[]).toPromise();
        }
        // le sessioni vengono filtrate per titolo
        return this.sessioniListDataService.dataList.pipe(
          map((items) =>
            items.filter(
              (item) =>
                item.Titolo && item.Titolo.toLowerCase().includes(term.term.toLowerCase())
            )
          )
        );
      }),
      tap(() => (this.sessioniSearching = false))
    );

    this.relazioniSearchResult = this.searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.relazioniSearching = true)),
      switchMap((term) => {
        if (
          term == null ||
          term.term == null ||
          term.term.replace(' ', '') === '' ||
          term.term.length < 3
        ) {
          let self = this;
          setTimeout(() => {
            self.relazioniSearching = false;
          }, 2000);
          return of([] as Relazione[]).toPromise();
        }
        // le rrelazioni vengono filtrate per titolo
        return this.relazioniListDataService.dataList.pipe(
          map((items) =>
            items.filter(
              (item) =>
                item.Titolo && item.Titolo.toLowerCase().includes(term.term.toLowerCase())
            )
          )
        );
      }),
      tap(() => (this.relazioniSearching = false))
    );
  }

  searchTermChanged(term: SearchTerm) {
    this.searchTerm.next(term);
  }

  clearSearchFailure(): void {
    this.relazioniSearchingFailed = false;
    this.sessioniSearchingFailed = false;
    this.personeSearchingFailed = false;
  }
}
