import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PnGenericListComponent } from '../../shared/components/pn-generic/pn-generic-list.component';
import { PnGenericComponent } from 'src/app/shared/components/pn-generic/pn-generic.component';
import { Sessione } from 'src/app/shared/models/sessione.model';
import { PersoneListDataService } from 'src/app/shared/services/persone-list-data.service';
import { Persona } from 'src/app/shared/models/persona.model';
import { SaleListDataService } from 'src/app/shared/services/sale-list-data.service';
import { Sala } from 'src/app/shared/models/sala.model';
import { SessioneDetailTag } from 'src/app/shared/models/sessioni-detail-tag.model';

@Component({
  selector: 'app-sessioni-row',
  templateUrl: './sessioni-row.component.html',
  styleUrls: ['./sessioni-row.component.scss']
})
export class SessioniRowComponent implements OnInit, AfterViewInit {
  private persone: Persona[] = [];
  private listaSale?: Sala[] = [];
  @Input('sessione') sessione!: Sessione;
  @Input('i') i!: number;

  constructor(
    @Inject(PersoneListDataService) private personeDataService: PersoneListDataService,
    @Inject(SaleListDataService) private saleDataService: SaleListDataService
  ) {
    this.saleDataService.dataList.subscribe((res) => {
      this.listaSale = res;
    });
    //Carico elenco persone
    this.getPersoneList();
   }

  trovaRelatore(item: Sessione){
    let id: number;
    let name: string;
    if(item.SessioniChairman && item.SessioniChairman.length > 0){
      id = item.SessioniChairman[0].PersonaId
      name = this.getNomePersonaById(id)
      return name
    }
    return ''
  }
  getPersoneList(){
    this.personeDataService.dataList.subscribe((list) => this.persone = list)
  }
  getNomePersonaById(id: number) {
    let pers: any;
    pers = this.persone.find(e => e.Id == id)
    if(pers != undefined){
      return PersoneListDataService.getDescrizionePersona(pers)
    }
    return ''
  }

  getNomeSalaById(salaID: number): string {
    if (this.listaSale) {
      const s = this.listaSale.find((el) => el.Id === salaID);
      if (s) {
        return s.Descrizione;
      }
    }
    return '';
  }

  ngOnInit(): void {
  }

  toolTip(i: SessioneDetailTag){
    if(this.sessione.SessioneDetailTag != undefined){
      return i.Descrizione
    }
    return ''
  }

  ngAfterViewInit(): void {
    console.log(this.sessione);

  }
}
