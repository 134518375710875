import { environment } from 'src/environments/environment';
import { NavigationRouteActionInfo } from '../models/navigation-action-info.model';

export enum ListNames {
  PERSONE_LIST_NAME = 'PERSONE',
  RELAZIONI_LIST_NAME = 'RELAZIONI',
  SALE_LIST_NAME = 'SALE',
  SESSIONI_LIST_NAME = 'SESSIONI',
  TAG_LIST_NAME = 'TAG',
  EVENTO_LIST_NAME = 'EVENTO',
}
/**
 * RoutePath: elenco delle route
 */
export enum RoutePath {
  // APP_MAIN_ROUTE = '',
  NOT_FOUND_ROUTE = '',
  // HOME_ROUTE = 'home',
  CRONOLOGICO_ROUTE = 'cronologico',
  PERSONE_ROUTE = 'persone',
  SALE_ROUTE = 'sale',
  SESSIONI_ROUTE = 'sessioni',
  TAG_ROUTE = 'tag',
  RELAZIONI_ROUTE = 'relazioni',
}

/**
 * elenco delle url per le interrogazioni del server
 */
export class ApiDatasourcePath {
  static PERSONE: string = environment.apiUrl + 'persone';
  static RELAZIONI: string = environment.apiUrl + 'relazioni';
  static SALE: string = environment.apiUrl + 'sale';
  static SESSIONI: string = environment.apiUrl + 'sessioni';
  static TAG: string = environment.apiUrl + 'tag';

  static LAST_UPDATE_DATES: string = environment.apiUrl + 'lastupdate';
  static FILE: string = environment.apiUrl + 'file';
  static EVENTO: string = environment.apiUrl + 'eventi';
}
/**
 * array contenente le info per i sotto menu da mostrare nell'header
 * quando si naviga tra le varie sezioni del programma
 */
export const NAVIGATION_ACTIONS: NavigationRouteActionInfo[] = [
  // {
  //   routeMatch: RoutePath.GESTIONI_ORARI_ROUTE + '$',
  //   items: [
  //     {
  //       path: [RoutePath.FORMAZIONE, RoutePath.EDIZIONI_SUBROUTE],
  //       title: 'FORMAZIONE',
  //       tooltip: 'Gestione formazione',
  //       icon: 'school',
  //       permessi: [Role.OrariAdmin, Role.OrariGestione],
  //     } as NavigationAction,
  //     {
  //       path: [RoutePath.GESTIONI_ORARI_ROUTE, RoutePath.FERIE_SUBROUTE],
  //       title: 'FERIE',
  //       tooltip: 'Inserimento ferie dipendenti',
  //       icon: 'flight',
  //       permessi: [Role.OrariAdmin, Role.OrariGestione],
  //     } as NavigationAction,
  //     {
  //       path: [RoutePath.GESTIONI_ORARI_ROUTE, RoutePath.MALATTIE_SUBROUTE],
  //       title: 'MALATTIE',
  //       tooltip: 'Inserimento malattie',
  //       icon: 'healing',
  //       permessi: [Role.OrariAdmin, Role.OrariGestione],
  //     } as NavigationAction,
  //   ],
  // },
];

export const LOCAL_STORAGE_NAVIGATION_KEY = "navHistory";
