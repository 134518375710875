import { RelazioneDetailTag } from './relazione-detail-tag.model';
import { RelazioneSpeaker } from './relazione-speaker.model';

/**
 *   Id: number;
 *
 *   SessioneId: number;
 *
 *   EventoId: number;
 *
 *   Titolo: string;
 *
 *   OraRelazione: Date;
 *
 *   OraFineRelazione: Date;
 *
 *   UrlAttionline: string;
 *
 *   UrlAbstract: string;
 *
 *   Mp3NameAttionline: string;
 *
 *   MainSpeakerId: number;
 *
 *   HaDiscussione: boolean;
 *
 *   Ordine: number;
 *
 *   OrdineSpeaker: string;
 *
 *   RelazioneSpeakers?: {@link RelazioneSpeaker}[];
 *
 *   RelazioneDetailTag?:  {@link RelazioneDetailTag}[];
 *
 */
export interface Relazione {
  Id: number;
  SessioneId: number;
  EventoId: number;
  Titolo: string;
  OraRelazione: Date;
  OraFineRelazione: Date;
  UrlAttionline: string;
  UrlAbstract: string;
  Mp3NameAttionline: string;
  HaDiscussione: boolean;
  Ordine: number;
  OrdineSpeaker: string;
  RelazioneSpeakers?: RelazioneSpeaker[];
  RelazioneDetailTag?: RelazioneDetailTag[];
}

export const RelazioniFakeData: Relazione[] = [
  {
    Id: 1,
    SessioneId: 1,
    EventoId: 1,
    Titolo:
      'Approfondimento su anatomia e aree di rischio per il miglior approccio alla correzione del volto con filler',
    OraRelazione: new Date(2021, 9, 14, 13, 30),
    OraFineRelazione: new Date(2021, 9, 14, 13, 50),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 1,
    OrdineSpeaker: '1',
  },
  {
    Id: 2,
    SessioneId: 1,
    EventoId: 1,
    Titolo:
      'Viscoderm Hydrobooster: Il trattamento delle aree dinamiche del volto',
    OraRelazione: new Date(2021, 9, 14, 14, 15),
    OraFineRelazione: new Date(2021, 9, 14, 14, 35),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 2,
    OrdineSpeaker: '1',
    RelazioneDetailTag: [
      {
        TagId: 1,
        Descrizione: 'tag test',
        RelazioneId: 2,
        EventoId: 1,
        Colore: '#0000ff',
      },
    ],
  },
  {
    Id: 3,
    SessioneId: 1,
    EventoId: 1,
    Titolo:
      'Labbra voluminose e definite? Il prodotto giusto a seconda delle esigenze del paziente. Video di tecnica iniettiva con i filler e tecnocologia Hylacross e Vycross',
    OraRelazione: new Date(2021, 9, 14, 15, 0),
    OraFineRelazione: new Date(2021, 9, 14, 15, 20),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
  {
    Id: 4,
    SessioneId: 2,
    EventoId: 1,
    Titolo: 'Introduzione',
    OraRelazione: new Date(2021, 9, 14, 14, 30),
    OraFineRelazione: new Date(2021, 9, 14, 14, 39),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
  {
    Id: 5,
    SessioneId: 2,
    EventoId: 1,
    Titolo:
      "I social media e l'estetica delle labbra: gusti e mode nelle varie fasce d'età",
    OraRelazione: new Date(2021, 9, 14, 14, 39),
    OraFineRelazione: new Date(2021, 9, 14, 14, 48),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
  {
    Id: 6,
    SessioneId: 2,
    EventoId: 1,
    Titolo: 'An update of lip anatomy: an evidence based approch',
    OraRelazione: new Date(2021, 9, 14, 14, 48),
    OraFineRelazione: new Date(2021, 9, 14, 14, 57),
    UrlAttionline: 'https://vimeo.com/708141155',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
  {
    Id: 7,
    SessioneId: 4,
    EventoId: 2,
    Titolo: 'La medicina rigenerativa in Medicina Estetica',
    OraRelazione: new Date(2021, 9, 14, 16),
    OraFineRelazione: new Date(2021, 9, 14, 16, 10),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
  {
    Id: 8,
    SessioneId: 4,
    EventoId: 2,
    Titolo: 'Protocolli di rigenerazione dei tessuti del volto',
    OraRelazione: new Date(2021, 9, 14, 16, 10),
    OraFineRelazione: new Date(2021, 9, 14, 16, 20),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
  {
    Id: 9,
    SessioneId: 4,
    EventoId: 2,
    Titolo: 'Protocolli di rigenerazione del capello',
    OraRelazione: new Date(2021, 9, 14, 16, 10),
    OraFineRelazione: new Date(2021, 9, 14, 16, 20),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
    RelazioneSpeakers: [
      {
        PersonaId: 10,
        RelazioneId: 9,
        Ordine: 2,
        IsMainSpeaker: false,
        EventoId: 2,
      },
    ],
  },
  {
    Id: 10,
    SessioneId: 3,
    EventoId: 1,
    Titolo: 'Introduzione',
    OraRelazione: new Date(2021, 9, 15, 9),
    OraFineRelazione: new Date(2021, 9, 15, 9, 8),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
  {
    Id: 11,
    SessioneId: 3,
    EventoId: 1,
    Titolo: 'Relazione fra COVID-19 ed eventi avversi',
    OraRelazione: new Date(2021, 9, 15, 9, 8),
    OraFineRelazione: new Date(2021, 9, 15, 9, 16),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
  {
    Id: 12,
    SessioneId: 3,
    EventoId: 1,
    Titolo: 'Filler e malattie autoimmuni',
    OraRelazione: new Date(2021, 9, 15, 9, 8),
    OraFineRelazione: new Date(2021, 9, 15, 9, 16),
    UrlAttionline: '',
    UrlAbstract: '',
    Mp3NameAttionline: '',
    HaDiscussione: false,
    Ordine: 3,
    OrdineSpeaker: '1',
  },
];
