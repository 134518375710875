import { Component, Inject } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Router, ActivatedRoute } from '@angular/router';
import { PnGenericListComponent } from '../shared/components/pn-generic/pn-generic-list.component';
import { Tag } from '../shared/models/tag.model';
import { MasterDataService } from '../shared/services/master-data.service';
import { TagListDataService } from '../shared/services/tag-list-data.service';
import { Color } from '../shared/utils/colors';
import { RoutePath } from '../shared/utils/constants';

/**
 * Cliccando sul pulsante Tag, verrà visualizzata la lista, in ordine alfabetico, dei tag utilizzati nel PN.
 * Per ognuno verrà mostrato:
 *  - nome
 *  - colore.
 *
 * Cliccando su un elemento della lista verrà visualizzato il componente Tag.
 */
@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent extends PnGenericListComponent<Tag> {


  constructor(
    @Inject(TagListDataService) dataService: TagListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService
  ) {
    super(dataService, router, route, refreshService);
  }

  onSelectionChanged(event: MatSelectionListChange): void {
    this.goToPage([RoutePath.TAG_ROUTE, event.options[0].value]);
  }

  coloreOpposto(colore: string): string {
    if (colore) {
      return Color.invertColor(colore);
    }
    return '';
  }
}
