<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="relazione-option-detail"
  fxLayoutGap="12px"
  fxFlex
>
  <img class="avatar" src="../../../assets/icons/icona user.png" alt="" *ngIf="urlFotoRelatore == ''"/>
  <svg
    *ngIf="urlFotoRelatore != ''"
    class="avatar"
    style="margin: auto 8px auto 8px"
    [ngStyle]="{ 'background-image': urlFotoRelatore }"
  ></svg>

  <div fxLayout="column" fxLayoutAlign="center center" style="min-width: fit-content;" class="date">
    <div *ngIf="sessione != undefined">
      <span *ngIf="showDateDetail">{{sessione.DataSessione | date: "dd/MM/yyyy" }}</span>
    </div>
    <div fxLayout="row" *ngIf="showDateDetail">
      <span>
        {{ relazione.OraRelazione | withTimeZone | date: "HH:mm" }}
      </span>
      -
      <span>
        {{ relazione.OraFineRelazione | withTimeZone | date: "HH:mm" }}
      </span>
    </div>
    <div fxLayout="column" *ngIf="!showDateDetail" fxLayoutAlign="space-around center">
      <span>
        {{ relazione.OraRelazione | withTimeZone | date: "HH:mm" }}
      </span>
      <span>
        {{ relazione.OraFineRelazione | withTimeZone | date: "HH:mm" }}
      </span>
    </div>
    
  </div>
  <div style="height: 90%; width: 1px; background-color: grey; margin-right: 12px; margin-top: auto; margin-bottom: auto;">
    <!-- spaziatore per evitare overlapping -->
  </div>
  <div style="margin: auto 0 auto 0" fxLayout="column" fxLayoutAlign="center stretch" fxFlex="70">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <span  style="font-weight: 900; margin-bottom: 6px; font-size: medium;" *ngIf="!nascondiNomeRelatore">
        {{descrizioneRelatore }}
      </span>
      <span style="font-size: small; font-weight: 700; text-align: end;" *ngIf="showSalaDetail">{{sala.Descrizione}}</span>
    </div>
    <span class="maxRows" style="font-size: small;">
      {{ relazione.Titolo | uppercase }}
    </span>
  </div>
</div>
