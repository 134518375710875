<mat-tab-group
  fxFill
  mat-align-tabs="center"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
>
  <mat-tab *ngFor="let day of days; let index = index" fxFill>
    <ng-template mat-tab-label>
      <div
        style="padding: 10px 20px; width: 100%"
        [ngStyle]="{
          'background-color':
            selected.value == index ? refreshService.coloreSecondario : '',
          color: selected.value == index ? refreshService.colorePrincipale : ''
        }"
      >
        <!-- formato data esempio: Mar 14 -->
        {{ day | date: "EE dd" | titlecase }}
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div  fxFill>
        <app-sessioni
          [showHeader]="false"
          [listValues]="sessionsByDays[day]"
        ></app-sessioni>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
