import { Evento } from './../models/evento.model';
import { LastUpdateFakeData } from './../models/last-update.model';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiDatasourcePath } from './../utils/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription, timer } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { PersoneListDataService } from './persone-list-data.service';
import { SessioniListDataService } from './sessioni-list-data.service';
import { TagListDataService } from './tag-list-data.service';
import { SaleListDataService } from './sale-list-data.service';
import { GenericListDataService } from './generic-list-data.service';
import { LastUpdate } from '../models/last-update.model';
import { switchMap } from 'rxjs/operators';
import { RelazioniListDataService } from './relazioni-list-data.service';
import { EventoDataService } from './evento-data.service';
import { TimeZoneService } from './timezone.service';

/**
 * Servizio che si occupa di gestire l'aggiornamento di tutti i dati del
 * programma navigabile
 *
 * Ha bisogno necessariamente di "eventId". Settandolo vengono
 * richiesti i dati al server per salvare nel localstorage tutti i tipi di risorsa
 *
 * * Viene istanziato un unico MasterDataService
 * * MasterDataService ha tanti ListDataService (per sale, persone, etc..) che estendono da GenericListDataService
 * * Ogni ListDataService ha il suo proprio LocalStorageManager
 * * quando in MasterDataService viene valorizzato EventId,
 *      - si setta EventId dei ListDataService
 *          + settando EventId dei ListDataService si imposta anche keyName di
 *            MasterDataService che recupera così i dati dal localStorage
 *      - e si fa partire il timer per chiedere al server le date delle ultime modifiche delle singole sezioni
 *  * quando il timer delle modifiche ottiene risposta
 *      - si aggiornano lastUpdateDate dei ListDataService
 *          + così facendo viene poi chiamato il metodo del ListDataService per richiedere eventualmente i dati al server
 *
 */
@Injectable({ providedIn: 'root' })
export class MasterDataService implements OnDestroy {
  constructor(
    private httpClient: HttpClient,
    private eventoService: EventoDataService,
    private personeService: PersoneListDataService,
    private sessioniService: SessioniListDataService,
    private saleService: SaleListDataService,
    private tagService: TagListDataService,
    private relazioniService: RelazioniListDataService,
    public timezoneService: TimeZoneService
  ) {
    this.programServices = [
      // this.eventoService,
      this.personeService,
      this.sessioniService,
      this.saleService,
      this.tagService,
      this.relazioniService,
    ];
    this.eventoService.eventProgramObs.subscribe(
      (result: Evento | undefined) => {
        // c'è stata uan qualche modifica all'evento che interessa il programma navigabile
        // si caricano i dati degli altri service
        if (result && result.Id) {
          this.onInfoEventChanged();
        } else {
          console.log('MasterDataService: missing evento in eventoService');
        }
      }
    );
  }

  /**
   * elenco di tutti i service (eccetto eventoService) da cui questi dipendono
   */
  private programServices: GenericListDataService<any>[];

  /**
   * restituisce elenco di tutti i service che chiedono i dati al server
   *
   * Dato dall'unione dei programServices e eventoService
   */
  private get allServerServices(): GenericListDataService<any>[] {
    return [...this.programServices, this.eventoService];
  }

  /**
   * Chiamato quando si ottengono le info dell'evento dal server
   *
   * Setta il valore di eventID di tutti i
   * service che caricano i dati dal server
   * riguardo alle info del programma navigabile
   *
   */
  private onInfoEventChanged(): void {
    if (
      this.eventoService.programmaEventoId &&
      this.eventoService.eventID == this.cms2EventId
    ) {
      this.programServices.forEach((s) => {
        s.eventID = this.eventoService.programmaEventoId!;
      });
      this.startTimerLastUpdateDates();
    } else {
      console.error('onInfoEventoCaricate error');
    }
  }

  /**
   * id dell'evento
   * Corrisponde alla prima parte della route
   */
  private _eventId?: number;

  get cms2EventId(): number {
    if (this._eventId) {
      return this._eventId;
    }
    return 0;
  }

  /**
   * setta il valore di cms2EventId di questo service
   * e di eventoService
   *
   * Viene chiamato da PnNavigationService al primo caricamento della pagina
   * quando viene ricavato id dell'evento del cms2
   */
  set cms2EventId(val: number) {
    this._eventId = val;
    this.eventoService.eventID = val;
    // si caricano solo i dati dell'evento
    // quando si hanno le info dell'evento poi si caricano
    // i dati degli altri service (relazioni, sessioni, etc...)
    this.eventoService.startServerDataTimer();

    // si imposta eventoId nel timezoneService così che possa inizializzarsi
    this.timezoneService.eventID = val;
  }

  private sub: Subscription = new Subscription();

  /**
   * true se uno dei service sta caricando dati dal server
   */
  get isLoadingData(): boolean {
    const services = this.allServerServices;
    for (let i = 0; i < services.length; i++) {
      const s = services[i];
      if (s.isLoadingData) {
        return true;
      }
    }
    return false;
  }

  logoUrl?: string; // TODO ricavare la url del logo dell'evento dal cms2??

  private timerSubscription?: Subscription;

  /**
   * chiede al server le date delle ultime modifiche ai dati
   * filtrando per evento id
   */
  private lastUpdateDatesObs(): Observable<LastUpdate[]> {
    if (environment.loadTestData) {
      const v = LastUpdateFakeData;
      v.forEach((e) => {
        e.LastUpdateDate = new Date();
      });
      return of(v);
    } else {
      if (this.eventoService.programmaEventoId) {
        console.log('MasterDataService getting LAST_UPDATE_DATES', new Date());
        const params: HttpParams = new HttpParams().set(
          'EventId',
          this.eventoService.programmaEventoId.toString()
        );
        return this.httpClient.get<LastUpdate[]>(
          ApiDatasourcePath.LAST_UPDATE_DATES,
          { params }
        );
      }
      throw new Error('lastUpdateDatesObs: missing programmaEventoId');
    }
  }

  /**
   * avvia il timer per controllare se ci sono nuovi dati sul server.
   * LastUpdateDate di ogni service si occuperà di fare eventuali nuove richieste al server
   */
  startTimerLastUpdateDates(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    // NOTA: startServerDataTimer in evento service gestisce in autonomia gli
    // aggiornamenti dell'evento del CMS2

    this.timerSubscription = timer(0, environment.dataRefreshTimer)
      .pipe(switchMap(() => this.lastUpdateDatesObs()))
      .subscribe((res) => {
        this.programServices.forEach((s) => {
          s.lastUpdateDate = res.find(
            (el) => el.Type === s.typeName
          )?.LastUpdateDate;
        });
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  get colorePrincipale(): string | undefined {
    return this.eventoService.colorePrincipale;
  }
  get coloreSecondario(): string | undefined {
    return this.eventoService.coloreSecondario;
  }
  get nomeEvento(): string | undefined {
    return this.eventoService.nomeEvento;
  }
}
