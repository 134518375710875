import { Injectable } from '@angular/core';
import { GenericListDataService } from './generic-list-data.service';
import { ApiDatasourcePath, ListNames } from '../utils/constants';
import { Sessione } from '../models/sessione.model';

/**
 * restituisce lista COMPLETA delle sessioni
 */
@Injectable({
  providedIn: 'root',
})
export class SessioniListDataService extends GenericListDataService<Sessione> {
  typeName = ListNames.SESSIONI_LIST_NAME;

  protected apiDatasourcePath = ApiDatasourcePath.SESSIONI;
}
