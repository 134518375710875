import { Persona } from '../shared/models/persona.model';
import { Component, Inject, Input } from '@angular/core';
import { PersoneListDataService } from '../shared/services/persone-list-data.service';
import { PnGenericListComponent } from '../shared/components/pn-generic/pn-generic-list.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSelectionListChange } from '@angular/material/list';
import { RoutePath } from '../shared/utils/constants';
import { MasterDataService } from '../shared/services/master-data.service';

/**
 * Cliccando sul pulsante Relatori e moderatori, verrà visualizzata la lista, in ordine alfabetico, dei soggetti che intervengono durante le sessioni dell’evento.
 *
 * Per ognuno verrà mostrato:
 *  + nome e cognome
 *  + foto se presente
 *  + numero di sessioni che modera
 *  + numero di relazioni che presenta.
 *
 * Cliccando su un elemento della lista verrà visualizzato il componente Persona.
 */
@Component({
  selector: 'app-persone',
  templateUrl: './persone.component.html',
  styleUrls: ['./persone.component.scss'],
})
export class PersoneComponent extends PnGenericListComponent<Persona> {
  constructor(
    @Inject(PersoneListDataService) dataService: PersoneListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService
  ) {
    super(dataService, router, route, refreshService);
  }

  @Input() sessioneID?: number;
  @Input() viewport: boolean = true;
  @Input() tipoPersonaID?: number;

  onSelectionChanged(event: MatSelectionListChange): void {
    this.goToPage([RoutePath.PERSONE_ROUTE, event.options[0].value]);
  }
}
