import { Injectable } from '@angular/core';
import { TZone } from 'moment-timezone-picker';
import {
  LocalStorageManager,
  LocalStorageRefService,
} from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneService {
  typeName = 'timezone';

  protected timezoneLocalStorageManager!: LocalStorageManager<TZone | null>;

  constructor(private _localStorageRefService: LocalStorageRefService) {}

  set eventID(val: number) {
    this.initializeTimeZoneManager(val);
  }

  initializeTimeZoneManager(eventId: number): void {
    this.timezoneLocalStorageManager = new LocalStorageManager<TZone | null>(
      this._localStorageRefService
    );
    this.timezoneLocalStorageManager.keyName = `programmanavigabile_${eventId}_timezone`;
  }

  timeZoneChanged(val: TZone | null) {
    console.log("NEW VAL", val);
    console.log("OLD VAL", this.timezoneLocalStorageManager.value);
    console.log("BOOL: ",this.timezoneLocalStorageManager.value?.nameValue != val?.nameValue);

    if(this.timezoneLocalStorageManager.value?.nameValue != val?.nameValue){
      this.timezoneLocalStorageManager.set(val);
      document.location.reload();
    }
  }

  get timeZoneValue(): TZone | null {
    return this.timezoneLocalStorageManager.value;
  }

  /**
   * ore del timezone
   */
  get hours(): number {
    return this.timeZoneHours(this.timezoneLocalStorageManager.value);
  }

  timeZoneHours(timeZone: TZone | null): number {
    if (timeZone && timeZone.timeValue) {
      const time = timeZone.timeValue.split(':')[0];
      return parseInt(time, 10);
    }
    return 0;
  }

  /**
   * minuti del timezone
   */
  get minutes(): number {
    return this.timeZoneMinutes(this.timezoneLocalStorageManager.value);
  }

  timeZoneMinutes(timeZone: TZone | null): number {
    if (timeZone && timeZone.timeValue) {
      const time = timeZone.timeValue.split(':')[1];
      return parseInt(time, 10);
    }
    return 0;
  }

  /**
   * true se il timezone inizia con +
   *
   * di default restituisce true
   */
  get isPositive(): boolean {
    return this.timeZoneIsPositive(this.timezoneLocalStorageManager.value);
  }

  timeZoneIsPositive(timeZone: TZone | null): boolean {
    return (
      timeZone?.timeValue?.startsWith('+') ?? true
    );
  }

  /**
   * Data una data aggiunge il timezone corrente
   * Le stringhe vengono convertite in data
   *
   * @param value Date | string | null
   * @returns Date | null
   */
  addTimeZoneToTime(value: Date | string | null): Date | null {
    if (value) {
      if (typeof value == 'string') {
        value = new Date(value);
      }
      console.log('DATA PRIMA:',value);
      if (this.timeZoneValue) {
        // t è della forma +01:00
        const toAdd = this.hours * 60 * 60 * 1000 + this.minutes * 60 * 1000;
        value.setTime(value.getTime() + (this.isPositive ? +toAdd : -toAdd) - this.referenceTimeZoneOffset);
      }
    }
    console.log('DATA DOPO:',value);
    return value as Date | null;
  }

  referenceTimeZone: TZone | null = null;
  get referenceTimeZoneOffset(): number {
    return this.timeZoneHours(this.referenceTimeZone) * 60 * 60 * 1000 - (this.timeZoneMinutes(this.referenceTimeZone) * 60 * 1000 * (this.timeZoneIsPositive(this.referenceTimeZone) ? +1 : -1));
  }
}
