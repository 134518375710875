export interface Persona {
  Id: number;
  Nome: string;
  Cognome: string;
  EventoId: number;
  Prefisso: string;
  Suffisso: string;
  UrlFoto: string;
}

export const PersoneFakeData: Persona[] = [
  {
    Id: 1,
    Nome: 'Raffaella',
    Cognome: 'Sommariva',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 2,
    Nome: 'Piersini',
    Cognome: 'Patrizia',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 3,
    Nome: 'Michela',
    Cognome: 'Taccola',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 4,
    Nome: 'Giuseppe',
    Cognome: 'Sito',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 5,
    Nome: 'Natalia',
    Cognome: 'Ribè',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 6,
    Nome: 'Tommaso',
    Cognome: 'Agostini',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 7,
    Nome: 'Lee',
    Cognome: 'Walker',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 8,
    Nome: 'Stefano',
    Cognome: 'Ceccarelli',
    EventoId: 2,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 9,
    Nome: 'Vittorio',
    Cognome: 'Gennaro',
    EventoId: 2,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 10,
    Nome: 'Maurizio',
    Cognome: 'Ceccarelli',
    EventoId: 2,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 11,
    Nome: 'Gaetano',
    Cognome: 'Cipriani',
    EventoId: 2,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 12,
    Nome: 'Paola',
    Cognome: 'Molinari',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 13,
    Nome: 'Federica',
    Cognome: 'Lerro',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 14,
    Nome: 'Claudio',
    Cognome: 'Plebani',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 15,
    Nome: 'Maurizio',
    Cognome: 'Cavallini',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
  {
    Id: 16,
    Nome: 'Pia',
    Cognome: 'Pirrotta',
    EventoId: 1,
    Prefisso: '',
    Suffisso: '',
    UrlFoto: '',
  },
];
