import { RoutePath } from './../../shared/utils/constants';
import { RelazioniListDataService } from 'src/app/shared/services/relazioni-list-data.service';
import { Relazione } from 'src/app/shared/models/relazione.model';
import { Component, Inject, Sanitizer } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { PersoneListDataService } from 'src/app/shared/services/persone-list-data.service';
import { RelazioneESessioneDetailComponent } from 'src/app/shared/components/relazione-e-sessione-detail.component';
import { EventoDataService } from 'src/app/shared/services/evento-data.service';
import { SaleListDataService } from 'src/app/shared/services/sale-list-data.service';
import { SessioniListDataService } from 'src/app/shared/services/sessioni-list-data.service';
import { Sessione } from 'src/app/shared/models/sessione.model';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

/**
 * Questo componente visualizza i dettagli di una relazione, in particolare:
  - titolo
  - data
  - ora inizio
  - ora fine
  - titolo della sessione. Cliccando sul titolo della sessione verrà visualizzato il componente Sessione
  - nome della sala. Cliccando sul nome della sala verrà visualizzato il componente Sala
  - lista dei relatori. Per ogni relatore verrà mostrato:
      + nome
      + cognome
      + immagine.

   Cliccando su un elemento della lista, verrà visualizzato il componente Persona.
   - link all’eventuale video on demand della relazione.
 */
@Component({
  selector: 'app-relazione-detail',
  templateUrl: './relazione-detail.component.html',
  styleUrls: ['./relazione-detail.component.scss'],
})
export class RelazioneDetailComponent extends RelazioneESessioneDetailComponent<Relazione> {
  constructor(
    @Inject(RelazioniListDataService) dataService: RelazioniListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService,
    @Inject(SaleListDataService) saleDataService: SaleListDataService,
    @Inject(EventoDataService) eventoDataService: EventoDataService,
    @Inject(PersoneListDataService)
    personeDataService: PersoneListDataService,
    @Inject(SessioniListDataService)
    private sessioniDataService: SessioniListDataService,
    private _sanitizer: DomSanitizer
  ) {
    super(
      dataService,
      router,
      route,
      refreshService,
      saleDataService,
      eventoDataService,
      personeDataService
    );
  }

  sessione?: Sessione;
  personeIds: number[] = [];

  get salaID(): number | undefined {
    return this.sessione?.SalaId;
  }

  private loadSessioneInfo(): void {
    if (this.element?.SessioneId) {
      this.sessioniDataService
        .getOne(this.element.SessioneId)
        .subscribe((res) => {
          if (res) {
            this.sessione = res;
            this.loadSalaInfo();
          }
        });
    }
  }

  onLoadedData(): void {
    super.onLoadedData();
    this.loadSessioneInfo();
    this.setPersoneIds();
  }

  onSessioneBtnClick(): void {
    this.goToPage([RoutePath.SESSIONI_ROUTE, this.element?.SessioneId]);
  }

  setPersoneIds(): number[] {
    this.personeIds = [];
    if (this.element) {
      this.personeIds = [
        ...(this.element.RelazioneSpeakers
          ? this.element.RelazioneSpeakers.map((el) => el.PersonaId)
          : []),
      ];
    }
    return [];
  }

  get relazioneUrl(): SafeUrl | undefined {
    if (this.element?.UrlAbstract) {
      return this._sanitizer.bypassSecurityTrustUrl(this.element?.UrlAbstract);
    }
    return;
  }
  safeVideoUrl(url: string):SafeResourceUrl | undefined{
    if(url != ""){
      return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return;
  }

  /**
   * true se possiamo mostrare il link per la diretta alla relazione
   */
  get canShowUrlAbstract(): boolean {
    if (!this.element || !this.element.UrlAbstract) {
      return false;
    }
    const now = this.refreshService.timezoneService.addTimeZoneToTime(
      new Date()
    );
    let oraRelazione = this.refreshService.timezoneService.addTimeZoneToTime(
      this.element.OraRelazione
    );
    // let oraFineRelazione =
    //   this.refreshService.timezoneService.addTimeZoneToTime(
    //     this.element.OraFineRelazione
    //   );
    // if (oraRelazione && oraFineRelazione) {
    //   if (oraRelazione > now && oraFineRelazione < now) {
    //     return true;
    //   }
    // }
    if (oraRelazione && now && oraRelazione > now) {
      return true;
    }
    return false;
  }


}
