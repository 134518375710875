import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { PnGenericDetailComponent } from 'src/app/shared/components/pn-generic/pn-generic-detail.component';
import { Sala } from 'src/app/shared/models/sala.model';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { SaleListDataService } from 'src/app/shared/services/sale-list-data.service';

/**
 * Questo componente visualizza i dettagli di una sala, in particolare:
  - nome
  - la lista delle sessioni presenti nella sala selezionata. Per ogni sessione verrà mostrato:
      + titolo
      + data
      + ora di inizio
      + ora di fine
      + sala
      + eventuale banda suddivisa fra i colori dei tag associati.

  Cliccando su un elemento della lista, verrà visualizzata la componente Sessione.
  - link all’eventuale streaming della sala.

 */
@Component({
  selector: 'app-sala-detail',
  templateUrl: './sala-detail.component.html',
  styleUrls: ['./sala-detail.component.scss'],
})
export class SalaDetailComponent extends PnGenericDetailComponent<Sala> {
  constructor(
    @Inject(SaleListDataService) dataService: SaleListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService,
    private _sanitizer: DomSanitizer
  ) {
    super(dataService, router, route, refreshService);
  }

  get hasStreaming(): boolean {
    if (this.element && this.element.StreamingUrl) {
      return true;
    }
    return false;
  }

  get streamingUrl(): SafeUrl | undefined {
    if (this.element?.StreamingUrl) {
      return this._sanitizer.bypassSecurityTrustUrl(this.element.StreamingUrl);
    }
    return;
  }
}
