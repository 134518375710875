<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
  <!--Immagine persona-->
  <img
  *ngIf="urlFotoPersona(persona) =='' || urlFotoPersona(persona) == undefined || urlFotoPersona(persona) == null"
    class="avatar"
    src="../../../assets/icons/icona user.png"
    alt=""

  />
  <svg
    *ngIf="urlFotoPersona(persona) !='' && urlFotoPersona(persona) != undefined && urlFotoPersona(persona) != null"
    class="avatar"
    [ngStyle]="{
      'background-image': urlFotoPersona(persona)
    }"
  ></svg>
  <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="2px" style="height: 80px;">
    <div>
      {{ descrizionePersona(persona) }}
    </div>
    <div
      *ngIf="showSummury"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="2px"
      style="color: rgb(118, 118, 118); font-size: 14px"
    >
      <ng-container *ngIf="sessioniConTipiPersone ">
        <div *ngFor="let sessione of sessioniConTipiPersone | keyvalue"><span i18n class="titolo-mansione">{{ getLableSessioni(sessione.key, sessione.value.length) }} : </span>{{ sessione.value.length }}
          <ng-container *ngIf="sessione.value.length == 1;"><span i18n>sessione</span></ng-container>
          <ng-container *ngIf="sessione.value.length > 1" ><span i18n>sessioni</span></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="relazioni && relazioni.length > 0">
        <div>
          <span i18n class="titolo-mansione">   {{getLabelRelazione(relazioni.length)}}  </span>{{ relazioni.length }}

        </div>
      </ng-container>


    </div>
  </div>
</div>
