import { SessioneChairman } from './sessione-chairman.model';
import { SessioneDetailTag } from './sessioni-detail-tag.model';
import { SessioneTipoPersona } from './tipo-persona.model';

/**
 * Id: number;
 *
 * SalaId: number;
 *
 * DataSessione: Date;
 *
 * EventoId: number;
 *
 * Titolo: string;
 *
 * OraInizio: Date;
 *
 * OraFine: Date;
 *
 * HaDiscussione: boolean;
 *
 * Descrizione: string;
 *
 * HaAttiOnline: boolean;
 *
 * HaAbstract: boolean;
 *
 * HaDocumenti: boolean;
 *
 * RelazioniIds: string;
 *
 * SessioneDetailTag?: {@link SessioneDetailTag}[];
 *
 * SessioniTipiPersone?: {@link SessioneTipoPersona}[];
 *
 * SessioniChairman?: {@link SessioneChairman}[];
 */
export interface Sessione {
  Id: number;
  SalaId: number;
  DataSessione: Date;
  EventoId: number;
  Titolo: string;
  OraInizio: Date;
  OraFine: Date;
  HaDiscussione: boolean;
  Descrizione: string;
  HaAttiOnline: boolean;
  HaAbstract: boolean;
  HaDocumenti: boolean;
  RelazioniIds: string;
  SessioneDetailTag?: SessioneDetailTag[];
  SessioniTipiPersone?: SessioneTipoPersona[];
  SessioniChairman?: SessioneChairman[];
}

export const SessioniFakeData: Sessione[] = [
  {
    Id: 1,
    SalaId: 1,
    DataSessione: new Date(2021, 9, 14),
    EventoId: 1,
    Titolo: 'Videosessione di apertura',
    OraInizio: new Date(2021, 9, 14, 13, 30),
    OraFine: new Date(2021, 9, 14, 14, 30),
    HaDiscussione: false,
    Descrizione: 'Test Uno',
    HaAttiOnline: false,
    HaAbstract: false,
    HaDocumenti: false,
    RelazioniIds: '',
  },
  {
    Id: 2,
    SalaId: 1,
    DataSessione: new Date(2021, 9, 14),
    EventoId: 1,
    Titolo:
      'LE LABBRA E LA RAGIONE PERITORIALE: UN MIGLIORAMENTO GLOBALE CON LE TECNICHE DI MEDICINA ESTETICA.',
    OraInizio: new Date(2021, 9, 14, 14, 30),
    OraFine: new Date(2021, 9, 14, 16, 35),
    HaDiscussione: true,
    Descrizione: 'Test Uno',
    HaAttiOnline: false,
    HaAbstract: false,
    HaDocumenti: false,
    RelazioniIds: '',
    SessioneDetailTag: [
      {
        TagId: 1,
        Descrizione: 'SESSIONE ECM',
        SessioneId: 1,
        EventoId: 1,
        Colore: '#ff0000',
      },
      {
        TagId: 2,
        Descrizione: 'SESSIONE GREEN',
        SessioneId: 1,
        EventoId: 1,
        Colore: '#18a32d',
      },
    ],
    SessioniTipiPersone: [
      {
        Id: 1,
        Descrizione: 'Moderatore',
        EventoId: 1,
        SessioneId: 1,
      },
      {
        Id: 2,
        Descrizione: 'Presidente e Coordinatore',
        EventoId: 1,
        SessioneId: 1,
      },
    ],
    SessioniChairman: [
      {
        PersonaId: 1,
        SessioneId: 1,
        TipoPersonaId: 1,
        EventoId: 1,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
      {
        PersonaId: 2,
        SessioneId: 1,
        TipoPersonaId: 2,
        EventoId: 1,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
    ],
  },
  {
    Id: 3,
    SalaId: 1,
    DataSessione: new Date(2021, 9, 15),
    EventoId: 1,
    Titolo: 'LE COMPLICANZE IN MEDICINA ESTETICA',
    OraInizio: new Date(2021, 9, 15, 9, 0),
    OraFine: new Date(2021, 9, 15, 10, 20),
    HaDiscussione: true,
    Descrizione: 'Test due',
    HaAttiOnline: false,
    HaAbstract: false,
    HaDocumenti: false,
    RelazioniIds: '',
    SessioneDetailTag: [
      {
        TagId: 1,
        Descrizione: 'SESSIONE ECM',
        SessioneId: 3,
        EventoId: 1,
        Colore: '#ff0000',
      },
      {
        TagId: 2,
        Descrizione: 'SESSIONE PROVA',
        SessioneId: 3,
        EventoId: 1,
        Colore: '#333333',
      },
    ],
    SessioniTipiPersone: [
      {
        Id: 1,
        Descrizione: 'Moderatore',
        EventoId: 1,
        SessioneId: 3,
      },
      {
        Id: 2,
        Descrizione: 'Presidente e Coordinatore',
        EventoId: 1,
        SessioneId: 3,
      },
    ],
    SessioniChairman: [
      {
        PersonaId: 12,
        SessioneId: 3,
        TipoPersonaId: 1,
        EventoId: 1,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
      {
        PersonaId: 13,
        SessioneId: 3,
        TipoPersonaId: 1,
        EventoId: 1,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
      {
        PersonaId: 14,
        SessioneId: 3,
        TipoPersonaId: 1,
        EventoId: 1,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
      {
        PersonaId: 15,
        SessioneId: 3,
        TipoPersonaId: 2,
        EventoId: 1,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
    ],
  },

  {
    Id: 4,
    SalaId: 3,
    DataSessione: new Date(2020, 9, 15),
    EventoId: 2,
    Titolo:
      "2020, VENT'ANNI DI MEDICINA RIGENERATIVA IN MEDICINA ESTETICA: ATTUALITA' SCIENTIFICHE E CLINICHE",
    OraInizio: new Date(2020, 9, 15, 16),
    OraFine: new Date(2020, 9, 15, 17),
    HaDiscussione: true,
    Descrizione: 'Test Due',
    HaAttiOnline: false,
    HaAbstract: false,
    HaDocumenti: false,
    RelazioniIds: '',
    SessioniTipiPersone: [
      {
        Id: 3,
        Descrizione: 'Moderatore',
        EventoId: 2,
        SessioneId: 2,
      },
      {
        Id: 4,
        Descrizione: 'Presidente e Coordinatore',
        EventoId: 2,
        SessioneId: 2,
      },
    ],
    SessioniChairman: [
      {
        PersonaId: 8,
        SessioneId: 2,
        TipoPersonaId: 3,
        EventoId: 1,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
      {
        PersonaId: 9,
        SessioneId: 2,
        TipoPersonaId: 3,
        EventoId: 2,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
      {
        PersonaId: 10,
        SessioneId: 2,
        TipoPersonaId: 4,
        EventoId: 2,
        OraInizio: new Date(),
        OraFine: new Date(),
      },
    ],
  },
];
