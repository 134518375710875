/**
 *  Id: number;
 *
 *  Descrizione: string;
 *
 *  Colore: string;
 *
 *  EventoId: number;
 *
 *  Ordine: number;
 */
export interface Tag {
  Id: number;
  Descrizione: string;
  Colore: string;
  EventoId: number;
  Ordine: number;
}

export const TagFakeData: Tag[] = [
  {
    Id: 1,
    Descrizione: 'SESSIONE ECM',
    Colore: 'red',
    EventoId: 1,
    Ordine: 1,
  },
  {
    Id: 2,
    Descrizione: 'SESSIONE CORTE',
    Colore: '',
    EventoId: 1,
    Ordine: 1,
  },
  {
    Id: 3,
    Descrizione: 'SESSIONE SICPRE',
    Colore: '',
    EventoId: 1,
    Ordine: 1,
  },
  {
    Id: 4,
    Descrizione: 'SESSIONE SANVENERO ROSSELLI',
    Colore: '',
    EventoId: 1,
    Ordine: 1,
  },
];
