import { RoutePath } from './../shared/utils/constants';
import { Component, Inject, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Relazione } from '../shared/models/relazione.model';
import { MasterDataService } from '../shared/services/master-data.service';
import { RelazioniListDataService } from '../shared/services/relazioni-list-data.service';
import { PnGenericListComponent } from '../shared/components/pn-generic/pn-generic-list.component';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-relazioni',
  templateUrl: './relazioni.component.html',
  styleUrls: ['./relazioni.component.scss'],
})
export class RelazioniComponent extends PnGenericListComponent<Relazione> {
  onSelectionChanged(event: MatSelectionListChange): void {
    let relazione: Relazione = event.options[0].value
    if(relazione.UrlAttionline != "" && relazione.UrlAttionline != null){
      this.goToPage([RoutePath.RELAZIONI_ROUTE, relazione.Id]);
    }
  }

  constructor(
    @Inject(RelazioniListDataService) dataService: RelazioniListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService
  ) {
    super(dataService, router, route, refreshService);
  }

  _sessioneID?: number;
  @Input() showDate!: boolean;
  @Input() showTime!: boolean;
  @Input() showSala!: boolean;
  @Input() nascondiNomeRelatore: boolean = false;
  @Input() viewport: boolean = true;

  /**
   * parametro per filtrare le relazioni in base alla sessioneID
   */
  @Input() set sessioneID(val: number | undefined) {
    this._sessioneID = val;
    if (val) {
      this.list = this.dataService.dataList.pipe(
        map((items) => items.filter((item) => item.SessioneId === val))
      );
    }
  }

  get sessioneID(): number | undefined {
    return this._sessioneID;
  }
}
