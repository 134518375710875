import { MasterDataService } from './master-data.service';
import { RoutePath } from './../utils/constants';
import {
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  NavigationAction,
  NavigationRouteActionInfo,
} from 'src/app/shared/models/navigation-action-info.model';
import { filter } from 'rxjs/operators';
import { NAVIGATION_ACTIONS } from '../utils/constants';

@Injectable({ providedIn: 'root' })
export class PnNavigationService {
  navigationActionsSubj = new BehaviorSubject<{
    routerInfo: NavigationAction[];
    parentPath: string | undefined;
  }>({ routerInfo: [], parentPath: undefined });

  /**
   * ultima route che era stata navigata
   */
  lastRoute?: string;

  constructor(
    private router: Router,
    private masterDataService: MasterDataService
  ) {
    this.router.events
      .pipe(
        // events contiene tutte le informazioni su tutti gli eventi di navigazione
        // qui siamo interessati solo a NavigationEnd
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        // qui event è adesso di tipo NavigationEnd
        this.onNavigationChange(event.url);
      });
  }

  /**
   * chiamato ogni volta che si cambia route
   * se url è null, si ricava da this.router.url
   *
   * @param url string
   */
  onNavigationChange(url: string | null): void {
    if (url == null) {
      url = this.router.url;
    }
    this.setEventId(url);
    const actions = this.getActionsByUrl(url);
    //if (!this.lastRoute || (actions && actions.routeMatch !== this.lastRoute)) {
    this.navigationActionsSubj.next({
      routerInfo: actions ? actions.items : [],
      parentPath: url,
    });
    /*} else {
      // altrimenti si svuotano le action
      this.navigationActionsSubj.next({ routerInfo: [], parentPath: url });
    }*/
    this.lastRoute = actions ? actions.routeMatch : undefined;
  }

  getActionsByUrl(url: string): NavigationRouteActionInfo | undefined {
    return NAVIGATION_ACTIONS.find((r) => new RegExp(r.routeMatch).test(url));
  }

  /**
   * Dalla url ricava id dell'evento
   *
   * @param url string
   */
  setEventId(url: string): void {
    // https://stackoverflow.com/questions/52501840/how-do-you-get-the-first-part-of-a-uri-in-angular-5
    const tree: UrlTree = this.router.parseUrl(url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    try {
      const id = parseInt(g.segments[0].path);
      if (this.masterDataService.cms2EventId != id) {
        // si aggiorna id dell'evento su masterDataService
        this.masterDataService.cms2EventId = id;
      }
    } catch (err) {
      console.error(err);
      this.router.navigateByUrl(RoutePath.NOT_FOUND_ROUTE);
      // TODO go to 404 page
    }
  }
}
