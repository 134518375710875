import { Component, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PnGenericDetailComponent } from 'src/app/shared/components/pn-generic/pn-generic-detail.component';
import { Tag } from 'src/app/shared/models/tag.model';
import { MasterDataService } from 'src/app/shared/services/master-data.service';
import { TagListDataService } from 'src/app/shared/services/tag-list-data.service';

@Component({
  selector: 'app-tag-detail',
  templateUrl: './tag-detail.component.html',
  styleUrls: ['./tag-detail.component.scss'],
})
export class TagDetailComponent extends PnGenericDetailComponent<Tag> {
  constructor(
    @Inject(TagListDataService) dataService: TagListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService
  ) {
    super(dataService, router, route, refreshService);
  }
}
