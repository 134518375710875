import { ListNames } from './../utils/constants';
/**
 *   Type: string;
 *
 *   EventId: number;
 *
 *   LastUpdateDate: Date;
 */
export interface LastUpdate {
  Type: string;
  EventoId: number;
  LastUpdateDate: Date;
}

export const LastUpdateFakeData: LastUpdate[] = [
  {
    Type: ListNames.PERSONE_LIST_NAME,
    EventoId: 1,
    LastUpdateDate: new Date(),
  },
  {
    Type: ListNames.RELAZIONI_LIST_NAME,
    EventoId: 1,
    LastUpdateDate: new Date(),
  },
  {
    Type: ListNames.SALE_LIST_NAME,
    EventoId: 1,
    LastUpdateDate: new Date(),
  },
  {
    Type: ListNames.SESSIONI_LIST_NAME,
    EventoId: 1,
    LastUpdateDate: new Date(),
  },
  {
    Type: ListNames.TAG_LIST_NAME,
    EventoId: 1,
    LastUpdateDate: new Date(),
  },
];
