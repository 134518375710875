import { Persona } from './../../shared/models/persona.model';
import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { PersoneListDataService } from 'src/app/shared/services/persone-list-data.service';
import { map } from 'rxjs/operators';
import { SessioneTipoPersona } from 'src/app/shared/models/tipo-persona.model';
import { RelazioniListDataService } from 'src/app/shared/services/relazioni-list-data.service';
import { SessioniListDataService } from 'src/app/shared/services/sessioni-list-data.service';
import { Sessione } from 'src/app/shared/models/sessione.model';
import { Relazione } from 'src/app/shared/models/relazione.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-persona-row',
  templateUrl: './persona-row.component.html',
  styleUrls: ['./persona-row.component.scss'],
})
export class PersonaRowComponent implements OnDestroy {
  private _persona!: Persona;
  @Input() get persona(): Persona {
    return this._persona;
  }

  set persona(value: Persona) {
    this._persona = value;
    this.loadSummaryInfo();
  }

  @Input() showSummury: boolean = true;

  /**
   * elenco delle sessioni suddivise in base alla descrizione del tipo persona
   */
  sessioniConTipiPersone: Map<string, Sessione[]> = new Map<
    string,
    Sessione[]
  >();

  /**
   * elenco delle relazioni
   */
  relazioni: Relazione[] = [];

  private sub: Subscription = new Subscription();

  constructor(
    @Inject(RelazioniListDataService)
    private relazioniDataService: RelazioniListDataService,
    @Inject(SessioniListDataService)
    private sessioniDataService: SessioniListDataService
  ) {}

  // nRelatore: number = 0;
  // nSessioniMap: Map<string, number> = new Map<string, number>();

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * si caricano le info su numero di relazioni della persona
   */
  private loadSummaryInfo(): void {
    this.loadRelazioni();
    this.loadSessioni();
  }

  /**
   * si caricano le sessioni in cui la persona è elencata tra i SessioniChairman
   */
  loadSessioni(): void {
    this.sub.add(
      this.sessioniDataService.dataList
        .pipe(
          map((values) =>
            values.map((i) => {
              const chairMan = i.SessioniChairman?.find(
                (c) => c.PersonaId === this.persona.Id
              );
              let tipo: SessioneTipoPersona | undefined;
              if (chairMan) {
                tipo = i.SessioniTipiPersone?.find(
                  (t) => t.Id === chairMan.TipoPersonaId
                );
              }
              return { sessione: i, tipo: tipo };
            })
          ),
          map((items) =>
            items.filter((el) =>
              el.sessione.SessioniChairman
                ? el.sessione.SessioniChairman.find(
                    (c) => c.PersonaId === this.persona.Id
                  ) != null
                : false
            )
          )
        )
        .subscribe((res) => {
          this.setSessioniConTipiPersone(res);
        })
    );
  }

  /**
   * si caricano le relazioni in cui compare la persona
   */
  loadRelazioni(): void {
    this.sub.add(
      this.relazioniDataService.dataList
        .pipe(
          map((items) =>
            items?.filter((el) =>
              el.RelazioneSpeakers
                ? el.RelazioneSpeakers.findIndex(
                    (el) => el.PersonaId === this.persona.Id
                  ) >= 0
                : false
            )
          )
        )
        .subscribe((res) => {
          this.relazioni = res;
        })
    );
  }

  /**
   * si costruisce la mappa che associa 'descrizione-tipo-persona' con le sessioni opportune
   * @param values { sessione: Sessione; tipo: TipoPersona | undefined; }[]
   */
  private setSessioniConTipiPersone(
    values: {
      sessione: Sessione;
      tipo: SessioneTipoPersona | undefined;
    }[]
  ): void {
    this.sessioniConTipiPersone = new Map<string, Sessione[]>();
    values.forEach((v) => {
      if (v.sessione) {
        const tipoPersona = v.tipo?.Descrizione ?? '';
        this.addToSessioniMap(
          this.sessioniConTipiPersone,
          tipoPersona,
          v.sessione
        );
      }
    });
  }

  private addToSessioniMap(
    m: Map<string, Sessione[]>,
    index: string,
    sessione: Sessione
  ): void {
    let valoriPresenti: Sessione[] | undefined;
    if (m.has(index)) {
      valoriPresenti = m.get(index);
    }
    let nuoviValori: Sessione[];
    if (valoriPresenti) {
      nuoviValori = valoriPresenti.concat(sessione);
    } else {
      nuoviValori = [sessione];
    }
    m.set(index, nuoviValori);
  }

  descrizionePersona(persona: Persona): string {
    return PersoneListDataService.getDescrizionePersona(persona);
  }

  urlFotoPersona(persona: Persona): string {
    let url = PersoneListDataService.getUrlFotoPersona(persona);
    return url;
  }

  getLabelRelazione(len: number): string {
    if(len == undefined || len == 0) {
      return ""
    }
    if (len == 1) {
      return 'Relazione:';
    }
      return 'Relazioni: ';
  }

  getLableSessioni(str: string, len: number): string {
    if(str != undefined && str != "") {
      return str
    }else if(len > 0){
      return "Moderatore"
    }
    return "";
  }
}
