import { SessioneChairman } from './../../shared/models/sessione-chairman.model';
import { SessioneTipoPersona } from './../../shared/models/tipo-persona.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tipi-persone',
  templateUrl: './tipi-persone.component.html',
  styleUrls: ['./tipi-persone.component.scss'],
})
export class TipiPersoneComponent implements OnInit {
  @Input() tipiPersone: SessioneTipoPersona[] = [];
  @Input() persone: SessioneChairman[] = [];

  constructor() {}

  ngOnInit(): void {}
}
