<div >
  <div *ngIf="viewport == true">
    <ng-container *ngTemplateOutlet="viewPort"></ng-container>
  </div>
  <div *ngIf="!viewport">
    <ng-container *ngTemplateOutlet="NOviewPort"></ng-container>
  </div>

</div>


<ng-template #viewPort>
  <cdk-virtual-scroll-viewport [itemSize]="80" class="main-virtual-viewport"  fxFlex style="overflow: auto; height: 85vh;" >
    <mat-selection-list
      class="persone-list"
      #selectionList
      [multiple]="false"
      (selectionChange)="onSelectionChanged($event)"
    >
      <mat-list-option
        *cdkVirtualFor="let persona of list | async; let i = index"
        [value]="persona.Id"
        class="list-option"
      >
        <app-persona-row [persona]="persona"></app-persona-row>
      </mat-list-option>
    </mat-selection-list>
  </cdk-virtual-scroll-viewport>
</ng-template>


<ng-template #NOviewPort>
  <div fxFlex style="overflow: auto;" >
    <mat-selection-list
      class="persone-list"
      #selectionList
      [multiple]="false"
      (selectionChange)="onSelectionChanged($event)"
    >
      <mat-list-option
        *ngFor="let persona of list | async; let i = index"
        [value]="persona.Id"
        class="list-option"
      >
        <app-persona-row [persona]="persona"></app-persona-row>
      </mat-list-option>
    </mat-selection-list>
  </div>
</ng-template>
  
