import { Persona } from './../../shared/models/persona.model';
import { Relazione } from './../../shared/models/relazione.model';
import { Component, Input, Inject, OnDestroy, OnInit } from '@angular/core';
import { PersoneListDataService } from 'src/app/shared/services/persone-list-data.service';
import { Subscription } from 'rxjs';
import { TimeZoneService } from 'src/app/shared/services/timezone.service';
import { SessioniListDataService } from 'src/app/shared/services/sessioni-list-data.service';
import { Sessione } from 'src/app/shared/models/sessione.model';
import { Sala } from 'src/app/shared/models/sala.model';
import { SaleListDataService } from 'src/app/shared/services/sale-list-data.service';

@Component({
  selector: 'app-relazione-row',
  templateUrl: './relazione-row.component.html',
  styleUrls: ['./relazione-row.component.scss'],
})
export class RelazioneRowComponent implements OnDestroy, OnInit {
  constructor(
    @Inject(PersoneListDataService)
    private personeDataService: PersoneListDataService,
    private timeZoneService: TimeZoneService,
    private sessioniDataService: SessioniListDataService,
    private salaDataService: SaleListDataService
  ) {}


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    if (this.relazione.Id == 6) {
      this.relazione.UrlAttionline =
      'https://player.vimeo.com/video/703929738?h=b9e7c58e22';
    }
  }

  private sub: Subscription = new Subscription();
  private _relazione!: Relazione;
  sessione!: Sessione;
  sala!: Sala;

  @Input() showDateDetail!: boolean;
  @Input() showSalaDetail!: boolean;
  @Input() boldTitle: boolean = false;
  @Input() nascondiNomeRelatore: boolean = false;
  @Input() nascondiOrario: boolean = false;
  @Input() showTag: boolean = true;

  @Input() set relazione(val: Relazione) {
    this._relazione = val;
    const mainSpeaker = this.relazione.RelazioneSpeakers?.find(s => s.IsMainSpeaker);
    if(mainSpeaker) {
      this.sub.add(
        this.personeDataService
          .getOne(mainSpeaker.PersonaId)
          .subscribe((res) => {
            this.persona = res;
          })
      );
    }
    this.sub.add(
      this.sessioniDataService.getOne(val.SessioneId).subscribe((el) => {
        if (el) {
          this.sessione = el;
          this.sub.add(
            this.salaDataService.getOne(el.SalaId).subscribe((el) => {
              this.sala = el;
            })
          );
        }
      })
    );

  }

  get relazione(): Relazione {
    return this._relazione;
  }

  persona?: Persona;

  get descrizioneRelatore(): string {
    return PersoneListDataService.getDescrizionePersona(this.persona);
  }

  get urlFotoRelatore(): string {
    return PersoneListDataService.getUrlFotoPersona(this.persona);
  }
}
