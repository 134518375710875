import { Directive, Input } from '@angular/core';
import { PnGenericComponent } from './pn-generic.component';

@Directive()
export abstract class PnGenericDetailComponent<
  T extends { Id: number }
> extends PnGenericComponent<T> {
  @Input() element?: T;

  ngOnInit() {
    const idString = this.route.snapshot.paramMap.get('Id');
    if (idString) {
      try {
        const id = parseInt(idString);
        this.dataService.getOne(id).subscribe((res) => {
          if (res != undefined) {
            this.element = res;
            this.onLoadedData();
          }
        });
      } catch (err) {
        console.error('PnGenericDetailComponent', err);
      }
    }
  }
  protected onLoadedData(): void {}
}
