<div  fxFill>
    <mat-selection-list
      class="sessioni-list"
      #selectionList
      [multiple]="false"
      (selectionChange)="onSelectionChanged($event)"
    >
      <mat-list-option
        *ngFor="let item of list | async; let i = index"
        [value]="item.Id"
        class="list-option sessione-option-detail"
      >
        <app-sessioni-row
          [i]="i"
          [sessione]="item"
          style="height: 100%;"
          fxLayout="column"
          fxLayoutAlign="center center"
        ></app-sessioni-row>
      </mat-list-option>
    </mat-selection-list>
  </div>
