<div
  fxLayout="row"
  fxLayoutAlign="start stretch"
  [ngClass]="{ 'list-option-odd': !(i % 2), 'list-option-even': i % 2 }"
  fxFill
>
  <!-- LEFT COLOR TILE -->
  <div  fxLayout="column" fxLayoutAlign="center start" style="height: 100%; width: 30px" >
    <ng-container *ngFor="let tag of sessione.SessioneDetailTag">
      <div
        class="sessione-tag"
        *ngIf="tag.Colore"
        [ngStyle]="{ 'background-color': tag.Colore }"
        fxFlex
        style="width: 30px;"
        [matTooltip]="toolTip(tag)"
      ></div>
    </ng-container>
    <!--qui ci vanno i colori dei tag associati-->
  </div>
  <!-- MAIN CONTENT -->
  <div fxLayout="row" fxLayoutAlign="start center"  class="mainContent" fxFlex>
    <!-- time -->
      <div fxLayout="column" fxLayoutAlign="center center" class="time"  >
        <!-- data -->
        <div  style="font-weight: 800; ">
          {{sessione.DataSessione| withTimeZone| date: "dd/MM/yyyy"| uppercase}}
        </div>
        <!-- orario -->
        <div class="orario">{{ sessione.OraInizio | withTimeZone | date: "HH:mm" }}-{{ sessione.OraFine | withTimeZone | date: "HH:mm" }}
        </div>
      </div>
      <!-- SEPARATOR -->
      <div style="height: 90%; width: 1px; background-color: grey; margin-right: 12px; margin-top: auto; margin-bottom: auto;" >

      </div>
      <!-- titolo e sala -->
      <div fxLayout="column" fxLayoutAlign="start stretch" >
        <div style="font-weight: 900; margin-bottom: 6px; font-size: large;" >
          {{ getNomeSalaById(sessione.SalaId) }}
        </div>
        <div class="maxRows" style="font-size: small;">
          {{ sessione.Titolo | uppercase }}
        </div>
      </div>
  </div>

  <div 
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon>navigate_next</mat-icon>
  </div>

</div>
