<div *ngIf="element" fxLayout="column" fxLayoutAlign="start stretch" fxFill>
  <app-persona-row
    [persona]="element"
    #personaRow
    [showSummury]="false"
  ></app-persona-row>
  <mat-divider style="padding: 10px"> </mat-divider>
  <ng-container *ngIf="personaRow.sessioniConTipiPersone">
    <ng-container
      *ngFor="let item of personaRow.sessioniConTipiPersone | keyvalue"
    >
      <h3 class="tipo-persona">
        {{ item.key }}
      </h3>
      <app-sessioni
        [showHeader]="false"
        [listValues]="item.value"
      ></app-sessioni>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="personaRow.relazioni && personaRow.relazioni.length > 0">
    <h3 class="tipo-persona">Relatore</h3>
    <app-relazioni
      [listValues]="personaRow.relazioni"
      fxFlex
      [showDate]="true"
      [showSala]="true"
      [nascondiNomeRelatore]="true"
      ></app-relazioni>
  </ng-container>
</div>
