import { ListNames } from './../utils/constants';
import { environment } from 'src/environments/environment';
import { OnDestroy, EventEmitter, Directive } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {
  LocalStorageManager,
  LocalStorageRefService,
} from './local-storage.service';
import { SessioniFakeData } from '../models/sessione.model';
import { PersoneFakeData } from '../models/persona.model';
import { RelazioniFakeData } from '../models/relazione.model';
import { TagFakeData } from '../models/tag.model';
import { SaleFakeData } from '../models/sala.model';
import { EventoFakeData } from '../models/evento.model';
import { map } from 'rxjs/operators';

/**
 * All’accesso il client verificherà la presenza, in local storage, di una copia del PN.
 * In caso negativo, il client richiederà il PN al server,
 * mostrando una barra di caricamento durante il dowload, lo
 * salverà su localstorage e, al termine del download, lo visualizzerà.
 * Una componente del client controllerà a intervalli di tempo la presenza
 * di aggiornamenti al PN pronti sul server. In caso positivo li
 * scaricherà sostituendoli all’attuale copia presente in localstorage.
 */
@Directive()
export abstract class GenericListDataService<T> implements OnDestroy {
  public isLoadingData: boolean = false;
  protected _sub: Subscription = new Subscription();
  protected _loadDataSub?: Subscription;

  protected localStorageManager!: LocalStorageManager<T[]>;

  abstract typeName: string;

  dataList!: Observable<T[]>;

  /**
   * apiDatasourcePath è il path per eseguire la request al server
   */
  protected abstract apiDatasourcePath: string;

  /**
   * eventEmitter che notifica quando la lista è stata aggiornata
   */
  dataUpdated: EventEmitter<T[]> = new EventEmitter<T[]>();

  /**
   * per eventoService è id dell'evento su CMS2
   * Per gli altri service è id evento su TuttoCongressiPlus
   */
  private _eventID!: number;

  get eventID(): number {
    return this._eventID;
  }

  /**
   * oltre a settare il valore di _eventID,
   * avvia il caricamento dei dati dal server
   */
  set eventID(val: number) {
    this._eventID = val;
    // settando keyName, si caricano i dati dal localStorage
    this.localStorageManager.keyName = `programmanavigabile_${this.eventID}_${this.typeName}`;
    // this.loadServerData();
  }

  /**
   * idExtractor per T
   * dato element di tipo T restituisce il suo id
   *
   * di default è ID
   *
   * @param element T
   * @returns number | string
   */
  protected idExtractor: (arg0: any) => number | string = (element) =>
    element?.ID | element?.Id;

  constructor(
    protected httpClient: HttpClient,
    protected route: ActivatedRoute,
    private _localStorageRefService: LocalStorageRefService
  ) {
    this.initializeStorage();
  }

  private initializeStorage(): void {
    this.localStorageManager = new LocalStorageManager<T[]>(
      this._localStorageRefService
    );
    this.dataList = this.localStorageManager.myData$;
  }

  protected prepareLoadParameters(): HttpParams {
    let p = new HttpParams();
    // si deve mettere come parametro l'id dell'evento
    if (this.eventID) {
      p = p.set('EventId', this.eventID.toString());
    } else {
      throw new Error('GenericListDataService: Missing EventId');
    }
    return p;
  }

  /**
   * ricarica i dati dal server
   * se è in corso una richiesta, questa viene annullata e ne viene
   * fatta una nuova
   *
   * @returns Promise<T[]>
   */
  protected loadServerData(): void {
    console.log(
      'GenericListDataService loadServerData ' + this.typeName,
      new Date()
    );
    if (environment.loadTestData) {
      this.loadFakeData();
    } else {
      this.isLoadingData = true;
      if (this._loadDataSub) {
        this._loadDataSub.unsubscribe();
      }
      this._loadDataSub = new Subscription();
      const params = this.prepareLoadParameters();
      this._loadDataSub.add(
        this.httpClient.get<T[]>(this.apiDatasourcePath, { params }).subscribe(
          (res) => {
            this.localStorageManager.set(res);
            this.isLoadingData = false;
          },
          (err) => {
            console.error(err);
            this.isLoadingData = false;
          }
        )
      );
    }
  }

  /**
   * carica i dati finti di test
   * senza caricamento dal server
   */
  private loadFakeData(): void {
    switch (this.typeName) {
      case ListNames.PERSONE_LIST_NAME:
        this.localStorageManager.set(
          PersoneFakeData.filter(
            (el) => el.EventoId === this.eventID
          ) as any as T[]
        );
        break;
      case ListNames.RELAZIONI_LIST_NAME:
        this.localStorageManager.set(
          RelazioniFakeData.filter(
            (el) => el.EventoId === this.eventID
          ) as any as T[]
        );
        break;
      case ListNames.SALE_LIST_NAME:
        this.localStorageManager.set(
          SaleFakeData.filter(
            (el) => el.EventoId === this.eventID
          ) as any as T[]
        );
        break;
      case ListNames.SESSIONI_LIST_NAME:
        this.localStorageManager.set(
          SessioniFakeData.filter(
            (el) => el.EventoId === this.eventID
          ) as any as T[]
        );
        break;
      case ListNames.TAG_LIST_NAME:
        this.localStorageManager.set(
          TagFakeData.filter((el) => el.EventoId === this.eventID) as any as T[]
        );
        break;
      case ListNames.EVENTO_LIST_NAME:
        this.localStorageManager.set(
          EventoFakeData.filter((el) => el.Id === this.eventID) as any as T[]
        );
        break;
      default:
        break;
    }
  }

  private _lastUpdateDate?: Date;

  get lastUpdateDate(): Date | undefined {
    return this._lastUpdateDate;
  }

  /**
   * oltre a settare il valore
   * controlla se sono da scaricare dal server nuovi dati
   */
  set lastUpdateDate(val: Date | undefined) {
    this._lastUpdateDate = val;
    if (this.hasToUpdate) {
      this.loadServerData();
    }
  }

  /**
   * dice se bisogna chiedere i dati al server o meno a
   * seconda delle date di aggiornamneto precedenti.
   *
   * se il lastUpdateDate del localStorage non è ancora settato
   * oppure se è antecedente all'ultimo aggiornamento registrato sul server,
   * si restituisce true
   *
   */
  private get hasToUpdate(): boolean {
    const d = this.localStorageManager.lastUpdateDate;
    return (
      d == undefined ||
      this.lastUpdateDate == undefined ||
      this.lastUpdateDate >= d
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    if (this._loadDataSub) {
      this._loadDataSub.unsubscribe();
    }
  }

  /**
   * Da dataList restituisce un Observable con un unico oggetto
   *
   * @param id number
   * @returns Observable<T | undefined>
   */
  getOne(id: number): Observable<T | undefined> {
    return this.dataList.pipe(
      map((items) => items.find((item) => this.idExtractor(item) === id))
    );
  }
}
