import { TimeZoneService } from './../services/timezone.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withTimeZone',
  pure: false,
})
export class WithTimeZonePipe implements PipeTransform {
  constructor(private timeZoneService: TimeZoneService) {}

  transform(
    value: Date | string | null,
    ...args: unknown[]
  ): Date | string | null {
    return this.timeZoneService.addTimeZoneToTime(value);
  }
}
