import { Relazione } from 'src/app/shared/models/relazione.model';
import { Injectable } from '@angular/core';
import { GenericListDataService } from './generic-list-data.service';
import { ApiDatasourcePath, ListNames } from '../utils/constants';

/**
 * restituisce lista COMPLETA delle sessioni
 */
@Injectable({
  providedIn: 'root',
})
export class RelazioniListDataService extends GenericListDataService<Relazione> {
  typeName = ListNames.RELAZIONI_LIST_NAME;

  protected apiDatasourcePath = ApiDatasourcePath.RELAZIONI;
}
