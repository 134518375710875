import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { PnGenericListComponent } from '../shared/components/pn-generic/pn-generic-list.component';
import { Sala } from '../shared/models/sala.model';
import { SaleListDataService } from '../shared/services/sale-list-data.service';
import { MatSelectionListChange } from '@angular/material/list';
import { RoutePath } from '../shared/utils/constants';
import { MasterDataService } from '../shared/services/master-data.service';

/**
 * Cliccando sul pulsante Sale, verrà visualizzata la lista, in ordine alfabetico, delle sale.
 *
 * Cliccando su un elemento della lista verrà visualizzato il componente Sala.
 */
@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss'],
})
export class SaleComponent extends PnGenericListComponent<Sala> {
  // @ViewChild('selectionList') selectionList!: MatSelectionList;

  constructor(
    @Inject(SaleListDataService) dataService: SaleListDataService,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService
  ) {
    super(dataService, router, route, refreshService);
  }

  onSelectionChanged(event: MatSelectionListChange): void {
    this.goToPage([RoutePath.SALE_ROUTE, event.options[0].value]);
  }
}
