export interface Evento {
  Id: number;

  Nome: string;

  Descrizione: string;

  AziendaId: number; //getter e setter lato server => si riferisce a AppSponsor->AziendaId con IsPCO true

  ColorePrincipale: string;

  ColoreSecondario: string;

  AndroidPushAPIKey: string;

  NascondiDatiPersonali: boolean;

  PulisciBarcodeUrl: boolean;

  GMTOffset: number;

  MenuPulsanti: boolean;

  PosizioneMenuTablet: string;

  HeaderSmartphoneUID: string;

  HeaderTabletUID: string;

  HomeSmartphoneUID: string;

  HomeTabletUID: string;

  LogoSoggettoUID: string;

  LocandinaUID: string;

  DataInizio: Date;

  DataFine: Date;

  Url: string;

  LinkEsternoUrl: boolean;

  Hashtag: string;

  // Lingue: LinguaEvento[];

  CategoriaEventoId: number;

  // CategoriaEvento?: CategoriaEvento;

  RegistrazioneDiscussione: boolean;

  EmailPoster: boolean;

  DocumentoProgramma: string;

  SerialNumber: string;

  NascondiSituazioneSale: boolean;

  ProgrammaNavigabileEventoId?: number;

  ProgrammaNavigabileColorePrincipale?: string;

  ProgrammaNavigabileColoreSecondario?: string;
}

/**
 * proprietà dell'evento da cui dipende il programma navigabile
 */
export const EVENT_PROGRAM_PROPERTIES = [
  'ColorePrincipale',
  'ColoreSecondario',
  'ProgrammaNavigabileEventoId',
  'ProgrammaNavigabileColorePrincipale',
  'ProgrammaNavigabileColoreSecondario',
];

export const EventoFakeData: Evento[] = [
  {
    Id: 1,
    Nome: 'Agorà 2021',
    Descrizione: 'Evento di test',
    AziendaId: 1,
    ColorePrincipale: '#ededed',
    ColoreSecondario: '#015856',
    ProgrammaNavigabileEventoId: 1,
    ProgrammaNavigabileColorePrincipale: '#ededed',
    ProgrammaNavigabileColoreSecondario: '#015856',
  } as Evento,
  {
    Id: 2,
    Nome: 'Agorà 2020',
    Descrizione: 'Eventoi di test',
    AziendaId: 1,
    ColorePrincipale: '#132170',
    ColoreSecondario: '#ffffff',
    ProgrammaNavigabileEventoId: 2,
    ProgrammaNavigabileColorePrincipale: '#132170',
    ProgrammaNavigabileColoreSecondario: '#ffffff',
  } as Evento,
];
