<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  style="width: 100vw; height: 100vh; overflow: hidden"
>
  <mat-toolbar
    class="mat-elevation-z2"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    [ngStyle]="{
      'background-color': masterDataService.coloreSecondario,
      color: masterDataService.colorePrincipale
    }"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
      <div fxFlex="nogrow" *ngIf="backAvailable">
        <button mat-icon-button (click)="goBack()" style="text-align: center">
          <mat-icon fxFlex style="margin-left: 5px">arrow_back_ios</mat-icon>
        </button>
      </div>
      <div fxFlex="nogrow">
        <button
          mat-icon-button
          (click)="sidenav.toggle()"
          style="text-align: center"
        >
          <mat-icon style="margin-bottom: 4px">menu</mat-icon>
        </button>
      </div>
      <div fxFlex="nogrow">
        <button *ngIf="showSpinner" mat-icon-button style="text-align: left">
          <mat-icon class="rotating">refresh</mat-icon>
        </button>
      </div>
    </div>
    <span class="navbar-title">{{ masterDataService.nomeEvento }}</span>
    <div class="buttons-menu">
      <button
        mat-icon-button
        style="text-align: left"
        (click)="rsidenav.toggle()"
      >
        <mat-icon fxFlex style="margin-left: 8px">search</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <mat-sidenav-container fxFlex>
    <mat-sidenav
      style="min-width: 15%"
      #sidenav
      mode="over"
      opened="false"
      class="app-sidenav"
      (click)="sidenav.close()"
      [ngStyle]="{
        'background-color': masterDataService.coloreSecondario,
        color: masterDataService.colorePrincipale
      }"
    >
      <div
        fxfill
        fxLayout="column"
        style="margin-top: 1%; height: 99%; box-sizing: border-box"
        fxLayoutAlign="space-between center"
      >
        <div fxLayout="column" style="width: 100%">
          <!-- <button
            mat-button
            [routerLink]="'/' + masterDataService.eventId"
            class="sidenav-btn"
            i18n
            [ngStyle]="{
              'background-color': masterDataService.coloreSecondario,
              color: masterDataService.colorePrincipale
            }"
          >
            <mat-icon class="sidenav-icon">home</mat-icon>Home
          </button> -->
          <button
            mat-button
            [routerLink]="[routePath.PERSONE_ROUTE]"
            class="sidenav-btn"
            i18n
            [ngStyle]="{
              'background-color': masterDataService.coloreSecondario,
              color: masterDataService.colorePrincipale
            }"
          >
            <mat-icon class="sidenav-icon">people</mat-icon>Persone
          </button>
          <button
            mat-button
            [routerLink]="[routePath.TAG_ROUTE]"
            class="sidenav-btn"
            i18n
            [ngStyle]="{
              'background-color': masterDataService.coloreSecondario,
              color: masterDataService.colorePrincipale
            }"
          >
            <mat-icon class="sidenav-icon">bookmark_border</mat-icon>Tag
          </button>
          <button
            mat-button
            [routerLink]="[routePath.SALE_ROUTE]"
            class="sidenav-btn"
            i18n
            [ngStyle]="{
              'background-color': masterDataService.coloreSecondario,
              color: masterDataService.colorePrincipale
            }"
          >
            <mat-icon class="sidenav-icon">meeting_room</mat-icon>Sale
          </button>
          <button
            mat-button
            [routerLink]="[routePath.CRONOLOGICO_ROUTE]"
            class="sidenav-btn"
            i18n
            [ngStyle]="{
              'background-color': masterDataService.coloreSecondario,
              color: masterDataService.colorePrincipale
            }"
          >
            <mat-icon class="sidenav-icon">schedule</mat-icon>Cronologico
          </button>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav
      style="min-width: 15%; padding: 12px"
      #rsidenav
      mode="over"
      opened="false"
      class="app-sidenav"
      (click)="sidenav.close()"
      position="end"
      [ngStyle]="{
        'background-color': masterDataService.coloreSecondario,
        color: masterDataService.colorePrincipale
      }"
    >
      <ng-container *ngTemplateOutlet="searchInput"></ng-container>
      <ng-moment-timezone-picker
        [clearable]="true"
        [ngModel]="masterDataService.timezoneService.timeZoneValue"
        (ngModelChange)="
          masterDataService.timezoneService.timeZoneChanged($event)
        "
        [getUserZone]="false"
        (click)="$event.stopPropagation()"
        customPlaceholderText="Timezone..."
        #timePicker
      >
      </ng-moment-timezone-picker>
    </mat-sidenav>
    <mat-sidenav-content class="main-padding">
      <!-- <mat-progress-spinner
        *ngIf="showSpinner"
        class="loading-shade"
        mode="indeterminate"
      >
      </mat-progress-spinner> -->
      <div ngStyle.gt-sm="padding: 0px 20%;" fxFlex>
        <div
          class="card-deck"
          id="content_filter_results"
          fxLayout="column"
          *ngIf="
            personeSearch.length > 0 ||
              sessioniSearch.length > 0 ||
              relazioniSearch.length > 0;
            else routerTemplate
          "
        >
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex></div>
            <h1 i18n fxFlex class="center" style="margin-bottom: 0">
              Risultati ricerca
            </h1>
            <div fxFlex class="right">
              <button mat-icon-button (click)="clearSearch()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider style="margin: 10px"> </mat-divider>
          <!-- Template PERSONE-->
          <ng-container *ngIf="personeSearch.length > 0">
            <h2 i18n>Persone:</h2>
            <app-persone
              [listValues]="personeSearch"
              [viewport]="false"
            ></app-persone>
          </ng-container>
          <!-- FINE Template PERSONE-->
          <ng-container *ngIf="sessioniSearch.length > 0">
            <h2 i18n>Sessioni:</h2>
            <!-- Template SESSIONI-->
            <app-sessioni
              [listValues]="sessioniSearch"
              [showHeader]="false"
            ></app-sessioni>
          </ng-container>
          <!-- FINE Template SESSIONI-->
          <ng-container *ngIf="relazioniSearch.length > 0">
            <h2 i18n>Relazioni:</h2>
            <!-- Template RELAZIONI-->
            <app-relazioni
              [listValues]="relazioniSearch"
              [viewport]="false"
            ></app-relazioni>
          </ng-container>

          <!-- FINE Template RELAZIONI-->
        </div>
        <ng-template #routerTemplate>
          <router-outlet></router-outlet>
        </ng-template>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ng-template #searchInput>
  <mat-form-field class="filter">
    <input
      matInput
      type="text"
      id="searchInput"
      name="searchInput"
      [(ngModel)]="term"
      (keyup)="onSearchChange()"
      (click)="$event.stopPropagation()"
    />
    <mat-placeholder
      [ngStyle]="{
        'background-color': masterDataService.coloreSecondario,
        color: masterDataService.colorePrincipale
      }"
      >Search</mat-placeholder
    >
    <div matSuffix style="display: flex">
      <mat-icon [inline]="true" (click)="term = ''" *ngIf="term"
        >clear</mat-icon
      >
      <mat-icon [inline]="true" *ngIf="!searching && !searchFailed"
        >search</mat-icon
      >
      <mat-icon
        [inline]="true"
        class="rotate-icon"
        *ngIf="searching && !searchFailed"
        >refresh</mat-icon
      >
      <mat-icon [inline]="true" color="warn" *ngIf="searchFailed"
        >error_ouline
      </mat-icon>
    </div>
  </mat-form-field>
</ng-template>
