import { Directive, Input } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Observable, of } from 'rxjs';
import { PnGenericComponent } from './pn-generic.component';

@Directive()
export abstract class PnGenericListComponent<T> extends PnGenericComponent<T> {
  private _list?: Observable<T[]>;

  get list(): Observable<T[]> {
    return this._list || of([] as T[]);
  }

  set list(values: Observable<T[]>) {
    this._list = values;
  }

  ngOnInit(): void {
    // this._list non è undefined, se è già stato settato da staticList
    if (this._list == undefined) {
      // se _list è undefined, si setta uguale a this.dataService.dataList
      this.list = this.dataService.dataList;
    }
  }

  /**
   * setta list dati i valori passati
   */
  @Input() set listValues(values: T[] | undefined) {
    if (values !== undefined) {
      this.list = of(values);
    }
  }

  abstract onSelectionChanged(event: MatSelectionListChange): void;
}
