import { Injectable } from '@angular/core';
import { GenericListDataService } from './generic-list-data.service';
import { HttpParams } from '@angular/common/http';
import { ApiDatasourcePath, ListNames } from '../utils/constants';
import { Persona } from '../models/persona.model';

/**
 * restituisce lista COMPLETA delle persone
 */
@Injectable({
  providedIn: 'root',
})
export class PersoneListDataService extends GenericListDataService<Persona> {
  static getDescrizionePersona(persona?: Persona): string {
    if (persona) {
      return `${persona?.Cognome} ${persona?.Nome}`;
    }
    return '';
  }
  static getUrlFotoPersona(persona?: Persona): string {
    if (persona && persona.UrlFoto && persona.UrlFoto != '') {
      let url: string;
      if (persona.UrlFoto.startsWith('http')) {
        url = persona.UrlFoto;
      } else {
        url = PersoneListDataService.getImageUrl(persona.UrlFoto);
      }
      return `url('${url}')`;
    }
    return "";
  }

  static getImageUrl(url: string): string {
    return ApiDatasourcePath.FILE + '/' + url;
  }

  typeName = ListNames.PERSONE_LIST_NAME;
  protected apiDatasourcePath = ApiDatasourcePath.PERSONE;

  prepareLoadParameters(): HttpParams {
    let p = super.prepareLoadParameters();
    p = p.set('sort', 'cognome, nome');
    return p;
  }
}
