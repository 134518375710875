import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

function getLocalStorage(): Storage {
  return localStorage;
}

@Injectable({ providedIn: 'root' })
export class LocalStorageRefService {
  get localStorage(): Storage {
    return getLocalStorage();
  }
}

/**
 * Service non provide in root.
 *
 * Viene usato in ogni GenericListDataService.
 * Ognuno ha il suo.
 *
 * https://blog.briebug.com/blog/managing-local-storage-in-angular
 */
export class LocalStorageManager<T> {
  private _localStorage: Storage;

  /**
   * stringa univoca per ricavare le informazioni dal localStorage
   */
  private _keyName?: string;

  get keyName(): string | undefined {
    return this._keyName;
  }

  /**
   * Della forma:
   *
   * `programmanavigabile_${this.eventID}_${this.localStorageName}`;
   *
   * Settando keyName, si ricaricano i dati
   */
  set keyName(val: string | undefined) {
    this._keyName = val;
    // si caricano i dati dal localStorage
    this.load();
  }

  get lastUpdateKeyName(): string {
    return `${this.keyName}_lastupdate`;
  }

  constructor(private _localStorageRefService: LocalStorageRefService) {
    this._localStorage = this._localStorageRefService.localStorage;
  }

  protected _myData$: BehaviorSubject<T> = new BehaviorSubject<T>({} as T);
  public myData$: Observable<T> = this._myData$.asObservable();

  /**
   * Salva sul localSotrage data di tipo T
   * sotto la chiave this.keyName
   *
   * @param data T
   */
  set(data: T) {
    if (!this.keyName) {
      throw new Error('LocalStorageManager: missing keyName');
    }
    const jsonData = JSON.stringify(data);
    this._localStorage.setItem(this.keyName, jsonData);
    this._localStorage.setItem(
      this.lastUpdateKeyName,
      new Date().toISOString()
    );
    this._myData$.next(data);
  }

  /**
   * carica i dati in this._myData$
   */
  load() {
    if (!this.keyName) {
      throw new Error('LocalStorageManager: missing keyName');
    }
    const res = this._localStorage.getItem(this.keyName);
    if (res) {
      const data: T = JSON.parse(res);
      this._myData$.next(data);
    }
  }

  /**
   * Restituisce la data dell'ultimo aggiornamento dei dati
   *
   * @returns Date
   */
  get lastUpdateDate(): Date | undefined {
    if (!this.lastUpdateKeyName) {
      throw new Error('LocalStorageManager: missing lastUpdateKeyName');
    }
    const res = this._localStorage.getItem(this.lastUpdateKeyName);
    if (res) {
      const data: Date = new Date(res);
      return data;
    }
    return undefined;
  }

  clear() {
    if (!this.keyName) {
      throw new Error('LocalStorageManager: missing keyName');
    }
    this._localStorage.removeItem(this.keyName);
    if (!this.lastUpdateKeyName) {
      throw new Error('LocalStorageManager: missing lastUpdateKeyName');
    }
    this._localStorage.removeItem(this.lastUpdateKeyName);
    this._myData$.next({} as T);
  }

  get value(): T {
    return this._myData$.getValue();
  }
  // clearAllLocalStorage() {
  //   this._localStorage.clear();
  //   this._myData$.next([]);
  // }
}
