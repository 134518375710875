import { environment } from 'src/environments/environment';
import { routeAnimations } from './../shared/animations/route.animations';
import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { PnNavigationService } from '../shared/services/pn-navigation.service';
import { ApiDatasourcePath, LOCAL_STORAGE_NAVIGATION_KEY, RoutePath } from '../shared/utils/constants';
import { MasterDataService } from '../shared/services/master-data.service';
import { SearchService, SearchTerm } from '../shared/services/search-service';
import { Persona } from '../shared/models/persona.model';
import { Relazione } from '../shared/models/relazione.model';
import { Sessione } from '../shared/models/sessione.model';
import { EventoDataService } from '../shared/services/evento-data.service';
import { MomentTimezonePickerComponent } from 'moment-timezone-picker';


/**
 * Barra sempre presente nella parte alta della pagina web, su cui verranno visualizzati:
  - il nome dell’evento
  - i pulsanti per i diversi percorsi di navigazione, in particolare:
      + relatori e moderatori
      + tag
      + sale
      + cronologico
  - il campo per la ricerca libera all’interno del PN
  - il selettore del fuso orario.

Tale barra e le funzionalità esposte rimarranno visibili e utilizzabili sempre, permettendo all’utente,
in ogni momento e a prescindere dei dati attualmente visualizzati di: cambiare
percorso di navigazione, selezionare un diverso fuso orario, effettuare una ricerca all’interno del PN.


FUSO ORARIO

In ogni momento, sarà possibile, dal selettore apposito sulla barra di navigazione,
modificare il fuso orario con cui visualizzare data e ora di sessioni e relazioni.
Quando viene cambiato il fuso orario, vengono automaticamente aggiornate le date
e le ore visualizzate attualmente e il nuovo fuso orario verrà mantenuto fino ad una nuova modifica.
Per far sì che si possa implementare in modo semplice questa funzionalità,
è necessario che tutte le date e le ore nel programma vengano salvate sul database in GMT.

 */
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [routeAnimations],
})
export class NavigationComponent implements OnDestroy, OnInit, AfterViewInit {
  @HostBinding("style.--color") color!: string;
  @ViewChild("timePicker",{static: true}) timePicker!: MomentTimezonePickerComponent;
  protected passwordSub!: Subscription;
  protected sub: Subscription = new Subscription();
  routePath = RoutePath;

  term: string | null = null;

  personeSearch: Persona[] = [];
  relazioniSearch: Relazione[] = [];
  sessioniSearch: Sessione[] = [];

  private _previousUrl?: string;
  private _currentUrl?: string;
  private _routeHistory: string[] = [];

  get backAvailable(): boolean {
    let histLenBool = this._routeHistory.length > 0;
    let strUrl = this.router.url;
    return strUrl != "/"+this.masterDataService.cms2EventId && histLenBool
  }

  private goingBack: boolean = false;

  constructor(
    public dialog: MatDialog,
    public navService: PnNavigationService,
    public masterDataService: MasterDataService,
    private searchService: SearchService,
    protected router: Router,
    private eventoDs: EventoDataService
  ) {

    this.eventoDs.eventoObs.subscribe((_) =>{
      this.color = this.masterDataService.colorePrincipale || 'black';
    });

    this.sub.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.clearSearch();
          if(!this.goingBack) {
            this._setURLs(event);
          } else {
            this.goingBack = false;
          }
        }
      })
    );
    this.searchService.sessioniSearchResult.subscribe((res) => {
      this.sessioniSearch = res;
    });

    this.searchService.relazioniSearchResult.subscribe((res) => {
      this.relazioniSearch = res;
    });

    this.searchService.personeSearchResult.subscribe((res) => {
      this.personeSearch = res;
    });
    const query = window.matchMedia("(max-width: 400px)");
    this.doCheck(query) // Call listener function at run time
    query.onchange = q => this.doCheck(q);
    query.addEventListener('change',q => {
      this.doCheck(q);
    }) // Attach listener function on state changes
  }
  ngAfterViewInit(): void {
    const eb = this.timePicker.timeZones.find(tz => tz.name.indexOf('Berlin') > 0) || null;
    this.masterDataService.timezoneService.referenceTimeZone = eb;
  }
  isMobile: boolean = false;
  doCheck(query: { matches: boolean; }) {
    this.isMobile = query.matches;
    console.log('QUERY MATCH',query.matches);
  }

  get appVersionNumber(): string {
    return environment.appVersion;
  }

  CssColorePrincipale: string = this.masterDataService.colorePrincipale || "yellow"
  CssColoreSecondario: string = this.masterDataService.coloreSecondario || "yellow"
  ngOnInit(): void {
    document.documentElement.style.setProperty('--CssColorePrincipale',`'${this.CssColorePrincipale}'`);
    document.documentElement.style.setProperty('--CssColoreSecondario',`'${this.CssColoreSecondario}'`);
  }

  colore = "red";

  clearSearch(): void {
    this.term = null;
    this.onSearchChange();
  }

  prepareRoute(outlet: RouterOutlet): void {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get showSpinner(): boolean {
    return this.masterDataService.isLoadingData;
  }

  onSearchChange() {
    const searchTerm = <SearchTerm>{
      term: this.term,
    };
    this.searchService.searchTermChanged(searchTerm);
  }

  get searching(): boolean {
    return this.searchService.searching;
  }

  get searchFailed(): boolean {
    return this.searchService.searchFailed;
  }

  getImageEventoUrl(url: string): String {
    return ApiDatasourcePath.FILE + '/' + url;
  }

  goBack() {
    if(this._routeHistory.length > 1){
      this._routeHistory.pop();
      this.goingBack = true;
      history.back();
    }else{
      this.router.navigate([this.masterDataService.cms2EventId]);
    }
  }

  private _setURLs(event: NavigationEnd): void {
    const tempUrl = this._currentUrl;
    this._previousUrl = tempUrl;
    this._currentUrl = event.urlAfterRedirects;
    this._routeHistory.push(event.urlAfterRedirects);
  }

  get previousUrl(): string | undefined {
    return this._previousUrl;
  }

  get currentUrl(): string | undefined {
    return this._currentUrl;
  }






}
