<div >
  <div style="text-align: center;">
    <h2>Tags</h2>
    <mat-divider style="margin-top: 24px;"    ></mat-divider>
  </div>
  <mat-selection-list
    class="tag-list"
    #selectionList
    [multiple]="false"
    id="tagWrapper"
    (selectionChange)="onSelectionChanged($event)"

  >
    <mat-list-option

      *ngFor="let tag of list | async; let i = index"
      [value]="tag.Id"
      class="list-option"
      [ngStyle]="{ 'background-color': tag.Colore ? tag.Colore : '#ffffff' }"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        [ngStyle]="{
          'background-color': tag.Colore ? tag.Colore : '#ffffff',
          color: coloreOpposto(tag.Colore ? tag.Colore : '#ffffff')
        }"

      >
        {{ tag.Descrizione }}
      </div>
    </mat-list-option>
  </mat-selection-list>
</div>
