import { Relazione } from 'src/app/shared/models/relazione.model';
import { Sessione } from 'src/app/shared/models/sessione.model';
import { Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Persona } from '../models/persona.model';
import { Sala } from '../models/sala.model';
import { EventoDataService } from '../services/evento-data.service';
import { GenericListDataService } from '../services/generic-list-data.service';
import { MasterDataService } from '../services/master-data.service';
import { PersoneListDataService } from '../services/persone-list-data.service';
import { SaleListDataService } from '../services/sale-list-data.service';
import { RoutePath } from '../utils/constants';
import { PnGenericDetailComponent } from './pn-generic/pn-generic-detail.component';
import { Color } from '../utils/colors';

@Directive()
export abstract class RelazioneESessioneDetailComponent<
  T extends Sessione | Relazione
> extends PnGenericDetailComponent<T> {
  constructor(
    dataService: GenericListDataService<T>,
    router: Router,
    route: ActivatedRoute,
    refreshService: MasterDataService,
    protected saleDataService: SaleListDataService,
    public eventoDataService: EventoDataService,
    public personeDataService: PersoneListDataService
  ) {
    super(dataService, router, route, refreshService);
    // TODO filtrare le persone. Prendere solo quelle legate alla sessione
    this.personeDataService.dataList.subscribe((res) => {
      if (res) {
        this.persone = res;
      }
    });
  }

  routePath = RoutePath;

  persone: Persona[] = [];
  sala?: Observable<Sala>;

  abstract get salaID(): number | undefined;

  loadSalaInfo(): void {
    if (this.salaID) {
      this.sala = this.saleDataService.getOne(this.salaID);
    }
  }

  onSalaBtnClicked(): void {
    if (this.salaID) {
      this.goToPage([RoutePath.SALE_ROUTE, this.salaID]);
    }
  }

  onPersonaBtnClicked(personaId: number): void {
    this.goToPage([RoutePath.PERSONE_ROUTE, personaId]);
  }

  onTagBtnClicked(tagID: number): void {
    this.goToPage([RoutePath.TAG_ROUTE, tagID]);
  }

  getDescrizionePersonaById(id: number): string {
    const p = this.persone.find((el) => el.Id === id);
    if (p) {
      return `${p.Cognome} ${p.Nome}`;
    }
    return '';
  }

  coloreOpposto(colore: string): string {
    if (colore) {
      return Color.invertColor(colore);
    }
    return '';
  }
}
