<div class="detail-container " *ngIf="element" fxFill fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="center center">
    <h1 style="margin-bottom: 0">{{ element.Descrizione }}</h1>
  </div>
  <mat-divider style="margin: 10px"> </mat-divider>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="hasStreaming"
    style="margin-top: 20px"
  >
    <a
      mat-stroked-button
      i18n
      [href]="streamingUrl"
      [ngStyle]="{
        'background-color': coloreSecondario,
        color: colorePrincipale
      }"
    >
      LIVE STREAMING
    </a>
  </div>
  <h2 i18n>Elenco Sessioni</h2>
  <app-sessioni fxFlex [showHeader]="false" [salaID]="element.Id"></app-sessioni>
</div>
