<div *ngIf="element" fxLayout="column" fxLayoutAlign="start center"  fxFill >
  <!--INIZIO TAG-->
  <div fxLayout="column" fxLayoutAlign="center center"  style="width: 100%" >
    <ng-container *ngFor="let tag of element.RelazioneDetailTag">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="tag.Colore"
        class="clickable"
        [ngStyle]="{ 'background-color': tag.Colore }"
        style="width: 100%; min-height: 15px"
        (click)="onTagBtnClicked(tag.TagId)"
      >
        <span [ngStyle]="{ color: coloreOpposto(tag.Colore) }">
          {{ tag.Descrizione }}
        </span>
      </div>
    </ng-container>
  </div>
  <!--FINE TAG-->

  <div style="width: 100%; background-color: white; margin-top: 30px;"  >

    <app-relazione-row
      style="padding: 24px; width: 100%;"
      [relazione]="element"
      [boldTitle]="true"
      [nascondiOrario]="true"
      [nascondiNomeRelatore]="true"
      [showTag]="false"
    ></app-relazione-row>
  </div>

  <div style="margin-top: 40px; ">
    <iframe
      [src]="safeVideoUrl(element.UrlAttionline)"
      width="640"
      height="360"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen ></iframe>
  </div>

  <!--FINE LISTA MODERATORI E RELATORI-->
</div>
