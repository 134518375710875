<div >
  <div fxLayout="row" fxLayoutAlign="center center">
    <h1 i18n="Sale welcome|Sale header@@introductionHeader">Sale</h1>
  </div>
  <mat-divider style="margin: 10px"> </mat-divider>
  <mat-selection-list
    #selectionList
    [multiple]="false"
    (selectionChange)="onSelectionChanged($event)"
  >
    <mat-list-option
      *ngFor="let item of list | async; let i = index"
      [value]="item.Id"
      class="list-option"
      [ngClass]="{ 'list-option-odd': !(i % 2), 'list-option-even': i % 2 }"
      style="text-align: center;"
    >
      {{ item.Descrizione | titlecase }}
    </mat-list-option>
  </mat-selection-list>
</div>
<!-- <div style="margin-bottom: 10px;">
  <router-outlet></router-outlet>
</div> -->
