/**
 *   Id: number;
 *
 *   EventoId: number;
 *
 *   Descrizione: string;
 *
 *   Colore: string;
 *
 *   Ordine: number;
 */
export interface Sala {
  Id: number;
  EventoId: number;
  Descrizione: string;
  Colore: string;
  Ordine: number;
  StreamingUrl?: string;
}

export const SaleFakeData: Sala[] = [
  {
    Id: 1,
    EventoId: 1,
    Descrizione: 'Sala Red',
    Colore: 'blue',
    Ordine: 1,
  },
  {
    Id: 2,
    EventoId: 1,
    Descrizione: 'Sala Blue',
    Colore: 'blue',
    Ordine: 2,
  },
  {
    Id: 3,
    EventoId: 2,
    Descrizione: 'Sala Yellow 1',
    Colore: 'blue',
    Ordine: 1,
  },
  {
    Id: 4,
    EventoId: 2,
    Descrizione: 'Sala Yellow 2',
    Colore: 'blue',
    Ordine: 1,
  },
];
