import { RelazioneDetailComponent } from './../relazioni/relazione-detail/relazione-detail.component';
import { PersonaDetailComponent } from './../persone/persona-detail/persona-detail.component';
import { SessioneDetailComponent } from './../sessioni/sessione-detail/sessione-detail.component';
import { TagComponent } from './../tag/tag.component';
import { RoutePath } from './../shared/utils/constants';
import { PersoneComponent } from './../persone/persone.component';
import { SaleComponent } from './../sale/sale.component';
import { Routes } from '@angular/router';
import { SessioniComponent } from '../sessioni/sessioni.component';
import { SalaDetailComponent } from '../sale/sala-detail/sala-detail.component';
import { CronologicoComponent } from '../cronologico/cronologico.component';
import { TagDetailComponent } from '../tag/tag-detail/tag-detail.component';

export const PROGRAMMA_NAVIGABILE_ROUTES: Routes = [
  {
    path: RoutePath.CRONOLOGICO_ROUTE,
    component: CronologicoComponent,
    data: {
      title: 'Cronologico',
      animation: 'CronologicoPage',
    },
  },
  {
    path: RoutePath.SESSIONI_ROUTE,
    component: SessioniComponent,
    data: {
      title: 'Sessioni',
      animation: 'SessioniPage',
    },
    // children: [
    //   {
    //     path: ':Id',
    //     component: SessioneDetailComponent,
    //     data: {
    //       title: 'SessioneDetail',
    //       animation: 'SessioneDetailPage',
    //     },
    //   },
    // ],
  },
  {
    path: RoutePath.SESSIONI_ROUTE + '/:Id',
    component: SessioneDetailComponent,
    data: {
      title: 'SessioneDetail',
      animation: 'SessioneDetailPage',
    },
  },
  {
    path: RoutePath.SALE_ROUTE,
    component: SaleComponent,
    data: {
      title: 'Sale',
      animation: 'SalePage',
    },
    // children: [
    //   {
    //     path: ':Id',
    //     component: SalaDetailComponent,
    //     data: {
    //       title: 'Sala',
    //       animation: 'SalaDetailPage',
    //     },
    //   },
    // ],
  },
  {
    path: RoutePath.SALE_ROUTE + '/:Id',
    component: SalaDetailComponent,
    data: {
      title: 'Sala',
      animation: 'SalaDetailPage',
    },
  },
  {
    path: RoutePath.PERSONE_ROUTE,
    component: PersoneComponent,
    data: {
      title: 'Persone',
      animation: 'PersonePage',
    },
    // children: [
    //   {
    //     path: ':Id',
    //     component: PersonaDetailComponent,
    //     data: {
    //       title: 'PersonaDetail',
    //       animation: 'PersonaDetailPage',
    //     },
    //   },
    // ],
  },
  {
    path: RoutePath.PERSONE_ROUTE + '/:Id',
    component: PersonaDetailComponent,
    data: {
      title: 'PersonaDetail',
      animation: 'PersonaDetailPage',
    },
  },
  {
    path: RoutePath.TAG_ROUTE,
    component: TagComponent,
    data: {
      title: 'Tag',
      animation: 'TagPage',
    },
  },
  {
    path: RoutePath.TAG_ROUTE + '/:Id',
    component: TagDetailComponent,
    data: {
      title: 'Tag',
      animation: 'TagDetailPage',
    },
  },
  {
    path: RoutePath.RELAZIONI_ROUTE + '/:Id',
    component: RelazioneDetailComponent,
    data: {
      title: 'Relazione',
      animation: 'RelazionePage',
    },
  },
  // {
  //   path: '**',
  //   component: NotFoundPageComponent,
  //   data: {
  //     title: '404',
  //     animation: '404Page',
  //   },
  // },
];
